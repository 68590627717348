import React, { useContext } from 'react';
import { StoreContext } from '../../containers/Store';
import { redirect } from '../../utils/location';

import * as ROUTES from '../../constants/routes';
import '../../scss/Home.scss';


const Home = () => {

  const clickModuleHandler = (id) => {
    redirect(`francais/premieres/module${id}/${ROUTES.GALLERY}`)
  }

  const {
    cmsAll: {
      modules
    }
  } = useContext(StoreContext);

  return <div className="home-page">
    <h1>Bienvenue 🤗</h1>
    <div className="message-home"><div>Merci d’avoir choisi de participer au projet Adaptiv’Langue dans le cadre du projet Innov’Up de la Région Île-de-France.</div></div>
    {
      modules.map((mod) => (
        <div key={mod.module} className="card module-title">
          <h1>{`Module ${mod.module} - ${mod.name}`}</h1>
          <button className="btn btn-validate" onClick={() => clickModuleHandler(mod.module)}>Explorer</button>
        </div>
      ))
    }
    <div className="logos">
      <img src="logo-idf.png" alt="logo Région Île-de-france" />
      <img src="logo-bpi-france.jpg" alt="logo Bpi France" />
      <img src="logo-cap-digital.jpg" alt="logo Cap Digital" />
      <img src="logo-academie-paris.jpg" alt="logo Académie de Paris" />
      <img src="logo-pv.png" alt="logo Lycée Paul Valéry" />
    </div>
  </div>
}

export default Home;
