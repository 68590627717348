import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import _ from "lodash";
import { StoreContext } from './Store';
import AI from './AI';
import ErrorBanner from '../components/ErrorBanner';
import * as Sentry from '@sentry/browser';
import moment from 'moment';

import useUpdate from '../hooks/useUpdate';
import useCheckUpdate from '../hooks/useCheckUpdate';
import useQueue from '../hooks/useQueue';
import useParseLocation from '../hooks/useParseLocation';
import { saveXapiDoc } from "../utils/xapi";

import * as KEYS from '../constants/xapi/keys';
import * as VERBS from '../constants/xapi/verbs';
import * as ROUTES from '../constants/routes';

import '../scss/GameLoop.scss';

import { NO_ACTIVITY_ERROR_MESSAGE, OFFLINE_MESSAGE, BACK_ERROR_MESSAGE } from '../constants/errorMessages';

import * as ROLES from '../constants/roles';

const GameLoop = ({ children, history }) => {
  const {
    user: {
      user
    },
    data: {
      indexes,
      dispatchIndexes,
      dispatchSequence,
      dispatchAI,
      resultsAI,
      xapiHistory,
      pushXapiHistory
    },
    cmsAll,
    drawer: {
      stepId: activityTeacher,
      pathId: pathTeacher,
    },
    feedback: {
      sentryId
    }
  } = useContext(StoreContext);

  const { modul, path: pathStudent, activityId: activityStudent, grade, subject } = useParseLocation();
  const moduleId = parseInt(modul.replace(/^module/, ''));
  let pathId, activityId;

  if (user && user.roles.includes(ROLES.STUDENT)) {
    pathId = pathStudent;
    activityId = activityStudent;
  } else if (user && user.roles.includes(ROLES.TEACHER)) {
    pathId = pathTeacher;
    activityId = activityTeacher;
  }
  pathId = parseInt(pathId)
  activityId = parseInt(activityId)

  const [errorMessage, setErrorMessage] = useState(null);

  const redoCall = () => {
    dispatchAI({ type: "CALL", payload: _.last(xapiHistory) });
  }

  const sendContext = (doc) => {
    const documents = doc.target.definition.extensions[KEYS.CONTENT];
    const context = {
      ...(documents.includes('HISTORY') && { history: xapiHistory }),
      ...(documents.includes('CMS') && { cms: JSON.stringify(cmsAll) }),
    };
    dispatchAI({ type: "SEND_CONTEXT", payload: context });
  }

  const changeActivity = (doc) => {
    const redirect = doc.target.definition.extensions[KEYS.CONTENT];
    if (redirect.module === moduleId && redirect.activity > activityId)
      return history.push(`/${subject}/${grade}/module${redirect.module}/${redirect.path}/${redirect.activity}/${ROUTES.CHATBOT}`)
    return dispatchIndexes({ type: "CHANGE_ACTIVITY", payload: redirect });
  }

  const addMessage = (doc) => {
    // TEMP : fix for the bug that prevents the user to do the next activity, it prevents the 2 messages that are displayed when the bug occurs to appear
    let a = _.get(doc, `target.definition.extensions["${KEYS.CONTENT}"]`);
    if(a.content !== "Haha, bien essayé 😊 Mais vous n'avez pas encore accès à cette activité."){
      if(a.content !== "Reprendre"){
        return dispatchSequence({
          type: "ADD_MESSAGE",
          payload: {
            ..._.get(doc, `context.extensions["${KEYS.LOCATION}"]`),
            lastItem: _.get(doc, `target.definition.extensions["${KEYS.CONTENT}"]`)
          }
        });
      }
    }
  }

  const performAction = (doc) => {
    const verb = doc.verb.id;
    
    switch (verb) {
      case VERBS.POSTED.id:
        return addMessage(doc);
      case VERBS.REQUESTED.id:
        return sendContext(doc);
      case VERBS.RECALLED.id:
        return redoCall(doc);
      case VERBS.CHANGED.id:
        /* 
          Function disabled temporary, induce a bug that prevents the user to do the next activity
          resetQueue(); 
        */
        return changeActivity(doc);
      case VERBS.LAUNCH.id:
        return dispatchIndexes({ type: "RESET_SEQUENCE_ACTIVITY", payload: {}});
    }
  }

  const AIHandler = () => {
    const result = _.last(resultsAI);
    const responses = _.get(result, 'actions');

    // remove the calculation time to the first message with timeout and not a defer action
    const duration = moment.duration(moment().diff(result.startAt)).asMilliseconds();
    const firstWaitItem = _.find(responses, (resp) => _.get(resp, `target.definition.extensions["${KEYS.OPTIONS}"].timeout`) && !_.get(resp, `target.definition.extensions["${KEYS.OPTIONS}"].defer`));
    const newDuration = _.get(firstWaitItem, `target.definition.extensions["${KEYS.OPTIONS}"].timeout`, 0) - duration;
    _.set(firstWaitItem, `target.definition.extensions["${KEYS.OPTIONS}"].timeout`, newDuration >= 0 ? newDuration : 0);
    console.log(responses)
    console.log("NEWAI WORKER RESPONSES", resultsAI);

    
    if (responses) {
      for (const doc of responses) {
        if (_.get(doc, `target.definition.extensions["${KEYS.OPTIONS}"].save`)) {
          const statement = saveXapiDoc(doc, user && user.roles.includes(ROLES.STUDENT) && "PROTECTED")
          dispatchAI({ type: "CALL", payload: statement, context: { historyLen: xapiHistory.length + 1 }});
          pushXapiHistory(statement);
        }
      }
      pushNextMessages(...responses);
    }
  };

  const checkResetQueue = () => ('LEAVE_ACTIVITY' === indexes.action);

  const [ , pushNextMessages, shiftNextMessages, resetQueue ] = useQueue((item) => {
    const timeoutId = setTimeout(() => {
      performAction(item);
      shiftNextMessages();
    }, _.get(item, `target.definition.extensions["${KEYS.OPTIONS}"].timeout`, 0));
    return () => (clearTimeout(timeoutId));
  });

  useUpdate(AIHandler, [resultsAI]);
  useCheckUpdate(() => { resetQueue() }, [indexes], checkResetQueue);

  return <AI>
    { errorMessage ? <ErrorBanner message={ errorMessage }/> : null }
    { children }
    { sentryId ? <button class="ant-btn ant-btn-primary report-btn" onClick={() => Sentry.showReportDialog({ eventId: sentryId, lang: 'fr' })}>Vous rencontrez une erreur sur cette page ?</button> : null }
  </AI>
}

export default withRouter(GameLoop);
