import { addExoKey } from './arrayOperations';
import { zeroPad } from './stringOperations';
import {flatten} from "lodash"

// parse score for path allocation
export const parseScore = (string) => {
  const array = string
    .replace('{', '')
    .replace('}', '')
    .replace(/\"/gi,'')
    .split(',')
    .map(kv => kv
      .trim()
      .split(':')
      .map(item => {
        console.log(item);
        return item.trim()
      })
    );
  return array.reduce((acc, [key, value]) => {
    acc[key] = parseInt(value);
    return acc;
  }, {});
};

export const shuffleArray = (unshuffled) => unshuffled
  .map((a) => ({sort: Math.random(), value: a}))
  .sort((a, b) => a.sort - b.sort)
  .map((item) => item.value)


export const randomizeExercices = (activity) => {
  const shuffledExerciceIds = shuffleArray([...new Set(activity.map(item => item.exercice))]);
  const shuffledExerciceIdsreduced = shuffledExerciceIds.reduce((acc, current, index) => {
    const exercice = activity
      .filter(item => item.exercice === current)
      .map(item => ({
        ...item,
        newExerciceId: index+1,
        newContentId: item.content_id
      }));
    acc.push(exercice);
    return acc
  }, []);
  return flatten(shuffledExerciceIdsreduced);
}

function getRandomArbitrary(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

export const selectFinalExercices = (exercices, nbActivities, moduleId, pathId, n) => {
  // A keys with random exercice nb between 11 and 13
  const keysOneRandomExercicePerActivity =[...Array(nbActivities).keys()].map(aId => {
    const rand = getRandomArbitrary(11,14);
    return `${moduleId}${zeroPad(pathId,  3)}${zeroPad(aId + 1,  3)}${zeroPad(rand,  3)}`
  });

  const oneRandomExercicePerActivity = exercices
    .map(addExoKey)
    .filter(exo => keysOneRandomExercicePerActivity.includes(exo.exoKey));

  const remainingExercices = exercices
    .map(addExoKey)
    .filter(exo => !keysOneRandomExercicePerActivity.includes(exo.exoKey));

  // we complete exercices up to n
  let count = nbActivities;
  const randomizedRemainingExercices = randomizeExercices(remainingExercices);

  const exos = randomizedRemainingExercices
    .reduce((acc, current, index, array) => {
      if (
        index === 0 ||
        current.exoKey !== array[index-1].exoKey
      ) {
        count += 1;
      }
      if (
        count <= n
      ) {
        acc.push(current);
      } 
      return acc;
    }, []);
  const randomExo = randomizeExercices([...oneRandomExercicePerActivity,...exos]);
  let exoId = 0;
  const result = randomExo.map((exo, index, array) => {
      if (index === 0) {
        exoId += 1
      } else {
        if (exo.type === 'message' && exo.type !== randomExo[index-1].type) {
          exoId += 1
        }
      }
      return {
        ...exo,
        newExerciceId: exoId
      }
    })
  return result;
}

export const parseQCMChoices = (str) => {
  if (str.includes('||')) {
    const temp = str.trim();
    if (temp.slice(0)[0] === '[' && temp.slice(-1)[0] === ']') {
      let cleanStr = temp
        .substr(1, temp.length-2)
        .split('||')
        .map(s => s.trim())
      return cleanStr      
    }
  }
  
}

export const parseQCMAnswers = (str) => {
  if (str.includes('||')) {
    const temp = str.trim();
    if (temp.slice(0)[0] === '[' && temp.slice(-1)[0] === ']') {
      return temp
        .substr(1, temp.length-2)
        .split('||')
        .map(s => s.trim())
    } else {
      return temp
        .split('||')
        .map(s => s.trim())
    }
  } else if (str.includes(',')) {
    return str.split(',');
  } else {
    return str.trim();
  }
}

export const parseQCMAnswer = (str) => {
  let numberPattern = /\d+/g;
  let indexes = str.match(numberPattern).map(Number);
  return indexes
}

export const parseDragChoices = (str) => {
  return parseQCMChoices(str);
}


export const parseDragAnswer = (str) => {
  return parseDragChoices(str).join(', ');
}

export const parseMVAnswer = (str) => {
  const temp = str.trim();
  if (temp.slice(0)[0] === '[' && temp.slice(-1)[0] === ']') {
    return temp
    .substr(1, temp.length-2)
  }
}

export const parseHLAnswer = (str) => {
  const temp = str.trim();
  if (temp.slice(0)[0] === '[' && temp.slice(-1)[0] === ']') {
    return temp
      .substr(1, temp.length-2)
      .split('||')
      .map(s => s.trim())
      .join(', ')
  } else {
    return temp
    .trim()
  }
}

export const parseContentQuestion = ({ type, content }) => ({
  QCMET: parseQCMChoices,
  QCMOU: parseQCMChoices,
  qcm: parseQCMChoices,
  drag: parseQCMChoices,
}[type] || (() => content))(content);

export const cleanAnswer = (item) => {
  let clean;
  if ( item.type === 'qcm' || item.type === 'QCMET' || item.type === 'QCMOU') {

    const choices = parseQCMChoices(item.content);
    const answer = parseQCMAnswer(item.correct_answer);

    console.log('IA JS', choices, answer)
    
    let conjunctiveLocution = item.type === "qcm" || item.type === "QCMOU" ? "ou" : "et";
    clean = answer.length === 1 ? `"${choices[answer[0]-1]}"` : answer.map(a => `"${choices[a-1]}"`).join(' ' + conjunctiveLocution + ' ');

  } else if (item.type === 'highlight_text') {
    clean = parseHLAnswer(item.correct_answer);
  } else if (item.type === 'drag') {
    clean = parseDragAnswer(item.correct_answer);
  } else if (item.type === 'missing_values') {
    clean = parseMVAnswer(item.correct_answer);
  }
  return clean
}

export const answerNumberOptions = (item) => {

  let res = 3
  if (item.type === 'qcm') {
    const choices = parseQCMChoices(item.content);
    res = choices.length;
  } else if (item.type === 'drag') {
    const choices = parseDragChoices(item.content);
    res = choices.length;
  }
  console.log('answerNumberOptions', res);
  return res;
}

