import React, { Fragment, useState } from 'react'
import { Button } from 'antd';
import uniqid from 'uniqid';

import '../scss/Choice.scss';

function Choice({content, getChoice, genUniqueId}) {
    const onClick = (value) => {
        getChoice(content[value]);
    }
    return (
        <Fragment>
            {
                !!content && content.length > 0 &&
                 <div
                    className="btn-choice">
                    {content.map((choice, i) =>
                       <Button
                            onClick={() => onClick(i)}
                            type="primary"
                            size="large"
                            key={uniqid()} > {choice} </Button>)}
                </div>
            }
        </Fragment>
    )
}

export default Choice;
