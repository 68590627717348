import React from 'react'

import logoEvidenceB from '../assets/images/evidenceb_logo.png';
import logoHatier from '../assets/images/hatier_logo.gif';
import '../scss/Logo.scss';

function Logo() {
    
    return (
        <div id="logo">
            <img className="logo hatier" src={logoHatier} alt='logo hatier' />
            <img className="logo evidenceb" src={logoEvidenceB} alt='logo evidenceb' />
        </div>
    )
}

export default Logo
