import { sameActivity, samePath, sameModule } from './same';
import * as ROUTES from '../constants/routes';
import * as PATH_MAP from '../constants/contentMap';
import * as KEYS from '../constants/xapi/keys';
import * as VERBS from '../constants/xapi/verbs';

const SUB_ID_REGEX = /^.*\//;
const FINAL_ID_REGEX = /^FINAL_TEST_(\d+)_(\d+)_(\d+)/;
const EXERCICE_ID = /exercices\//;
const ACTIVITY_ID = /activities\//;

const simulateFinalActivity = (moduleId, pathId, cms) => ({
  module: moduleId,
  path: pathId,
  activity: cms.activities.filter((elem) => samePath(elem, { module: moduleId, path: pathId })).length
});

const getId = (url) => url.replace(SUB_ID_REGEX, '');

const getExerciceFromId = (id, cms) => cms.exercices.find(elem => elem.id === id);

const getActivityFromExercice = (exo, cms) => {
  if (exo.path === 0) return cms.activities.find((elem) => sameActivity(elem, exo));
  if (exo.exercice > 10) return simulateFinalActivity(exo.module, exo.path, cms);
  if (exo.exercice <= 10) return cms.activities.find((elem) => sameActivity(elem, exo));
  return null;
}

const getActivityFromId = (id, cms) => {
  const res = id.match(FINAL_ID_REGEX);
  if (res && res.length) return { module: parseInt(res[1]), path: parseInt(res[2]), activity: parseInt(res[3]) };
  return cms.activities.find((elem) => elem.id === id);
}

export const getActivityFromXapiId = (url, cms) => {
  const id = getId(url);
  switch (true) {
    case EXERCICE_ID.test(url): return getActivityFromExercice(getExerciceFromId(id, cms), cms);
    case ACTIVITY_ID.test(url): return getActivityFromId(id, cms);
    default: return null;
  }
}

export const getDataFromXapiId = (url, cms) => {
  const id = getId(url);
  switch (true) {
    case EXERCICE_ID.test(url): return getExerciceFromId(id, cms);
    case ACTIVITY_ID.test(url): return getActivityFromId(id, cms);
    default: return null;
  }
}

export const generateUrlFromActivity = (activity) => {
  console.log(activity)
  const end_point = activity.path == 0 ? ROUTES.CHATBOT : ROUTES.PATH;
  if (!activity.module)
    return '/'
  return `/${PATH_MAP.KEY_FR[activity.subject]}/${PATH_MAP.KEY_FR[activity.grade]}/module${activity.module}/${activity.path}/${activity.activity}/${end_point}`;
}

export const getDetailsFromXapiDoc = (doc) => (_.get(doc, `target.definition.extensions['${KEYS.DETAILS}']`))

export const getLastActivity = (history, moduleId, subject, grade) => {
  const last_activity = [...history].reverse().find((elem) => (
    (!moduleId || sameModule(getDetailsFromXapiDoc(elem), { module: moduleId })) && (elem.verb.id === VERBS.LAUNCH.id)
  ));
  return {
    subject: PATH_MAP.FR_KEY[subject] || subject,
    grade: PATH_MAP.FR_KEY[grade] || grade,
    module: moduleId,
    path: 0,
    activity: 0,
    ...getDetailsFromXapiDoc(last_activity)
  }
}

export const getLastUrl = (history, moduleId, subject, grade) => (generateUrlFromActivity(getLastActivity(history, moduleId, subject, grade)))
