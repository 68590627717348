import React, { useContext, useState, useEffect, useRef, createRef } from 'react';
import { StoreContext } from '../containers/Store';
import * as ROUTES from '../constants/routes';
import * as ROLES from '../constants/roles';
import { useLocation, NavLink, Link } from 'react-router-dom';
import { Icon } from 'antd';
import useParseLocation from '../hooks/useParseLocation';
import { getLastUrl } from '../utils/history';
import { redirect } from "../utils/location";

import '../scss/Navigation.scss';


const Navigation = () => {
  const {
    user: { 
      user: authUser
    },
    data: {
      xapiHistory,
    },
    cmsAll
  } = useContext(StoreContext);

  const allModules = cmsAll.modules;
  let location = useLocation();
  const { subject, grade, modul } = useParseLocation();
  const currentUrl ='/' + subject + '/' + grade + '/' + modul ;

  const [ showMenu, setShowMenu ] = useState(false);
  const [ showNav, setShowNav ] = useState(false);

  const getModuleUrl = (modId) => {
    const defaultSubject = 'francais'
    const defaultGrade = 'premieres'
    let targetUrl;
    if (authUser.roles.includes(ROLES.STUDENT)) {
      targetUrl = getLastUrl(xapiHistory, modId, defaultSubject, defaultGrade);
    } else {
      targetUrl = '/'+ defaultSubject +'/'+ defaultGrade +'/module'+ modId +'/galerie';
    }
    redirect(targetUrl);
  };

  const getCurrentModuleName = () => {
    if (allModules) {
      const result = allModules.filter(element => modul && element.module === parseInt(modul.replace('module', '')));
      if (result[0]) {
        return result[0].name;
      } else {
        return "Modules";
      }
    }
  }

  // Handle side menu closing when resizing
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1194) {
        setShowNav(false);
        setShowMenu(false);
      } else {
        setShowNav(true);
        setShowMenu(true);
      }
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  })

  const openNav = () => {
    setShowNav(true);
    navRef.current.className = "menu-container open";
  }

  const closeNav = () => {
    setShowNav(false);
    setShowMenu(false);
    navRef.current.className = "menu-container";
  }

  const navRef = useRef(null);
  const menuRef = createRef(null);

  const studentLogout = window.redirectToHatier ? window.redirectToHatier : ROUTES.LOGOUT;
  const teacherLogout = window.redirectToHatier ? window.redirectToHatier : currentUrl + '/logout';

  const studentNav =
    <>
      <div className="mobile-menu-open" onClick={openNav}>
        <Icon type="menu" />
      </div>
      <div className="menu-container" ref={navRef}>
        <div className="mobile-menu-close" onClick={closeNav}>
          <Icon type="close" />
        </div>
        {/* <div className="nav-item">
          <NavLink to="#" exact onClick={() => setShowMenu(!showMenu)}>
            <Icon type="appstore" />
            <span>{ getCurrentModuleName() }</span>
            <Icon type={showMenu ? "up" : "down"} />
          </NavLink>
        </div> */}
        <div className="nav-item">
          <NavLink 
            to={modul ? ROUTES.CHATBOT : ''}
            activeClassName='selected'
            onMouseEnter={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
          >
              <Icon type="robot" />
              <span>{ getCurrentModuleName() }</span>
              <Icon type={showMenu ? "up" : "down"} />
          </NavLink>
          {showMenu && showNav && 
            <div 
              className="modules-list-side"
              onMouseEnter={() => setShowMenu(true)}
              onMouseLeave={() => setShowMenu(false)}
            >
              {allModules && allModules.map((module, i) => {
                return (
                  <div key={i} className="module-item">
                    <Link 
                      to="#"
                      onClick={() => getModuleUrl(module.module)}
                    >
                      {"M"+ module.module +" - "+ module.name}
                    </Link>
                  </div>
                )
              })}
            </div>
          }
        </div>
        { modul &&
          <div className="nav-item">
            <NavLink to={ROUTES.PATH} activeClassName='selected'>
              <Icon type="rise" />
              <span> Progression </span>
            </NavLink>
          </div>
        }
        { modul &&
          <div className="nav-item">
            <NavLink to={ROUTES.DASHBOARD} activeClassName='selected'>
              <Icon type="dashboard" />
              <span> Tableau de bord </span>
            </NavLink>
          </div>
        }
        <div className="nav-item btn-logout">
          <Link to={studentLogout}>
            <Icon type="poweroff" />
            <span>Se déconnecter</span>
          </Link>
        </div>
      </div>
    </>

  const teacherNav =
    <>
      <div className="mobile-menu-open" onClick={openNav}>
        <Icon type="menu" />
      </div>
      <div className="menu-container" ref={navRef}>
        <div className="mobile-menu-close" onClick={closeNav}>
          <Icon type="close" />
        </div>
        {/* <div className="nav-item">
          <NavLink to={currentUrl + '/' + ROUTES.GALLERY} activeClassName='selected'>
            <Icon type="rise" />
            <span> Plan du module </span>
          </NavLink>
        </div> */}
        <div className="nav-item">
          <NavLink to="/accueil">Accueil</NavLink>
        </div>
        <div className="nav-item">
          <NavLink 
            to={modul ? `${currentUrl}/${ROUTES.GALLERY}` : ''}
            activeClassName='selected'
            onMouseEnter={() => setShowMenu(true)}
            onMouseLeave={() => setShowMenu(false)}
          >
              <Icon type="robot" />
              <span>Galerie</span>
              <Icon type={showMenu ? "up" : "down"} />
          </NavLink>
          {showMenu && showNav && 
            <div 
              className="modules-list-side"
              onMouseEnter={() => setShowMenu(true)}
              onMouseLeave={() => setShowMenu(false)}
            >
              {allModules && allModules.map((module, i) => {
                return (
                  <div key={i} className="module-item">
                    <Link 
                      to="#"
                      onClick={() => getModuleUrl(module.module)}
                    >
                      {"M"+ module.module +" - "+ module.name}
                    </Link>
                  </div>
                )
              })}
            </div>
          }
        </div>
        { modul &&
          <div className="nav-item">
            <NavLink to={`${currentUrl}/${ROUTES.DASHBOARD}`} activeClassName='selected'>
              <Icon type="dashboard" />
              <span> Tableau de bord </span>
            </NavLink>
          </div>
        }
        <div className="nav-item btn-logout">
          <Link to={teacherLogout}>
            <Icon type="poweroff" />
            <span>Se déconnecter</span>
          </Link>
        </div>
      </div>
    </>


  if (location.pathname.includes(ROUTES.DEMO)) {
    return null
  } else {
    if (authUser) {
      if (authUser.roles.includes(ROLES.STUDENT)) {
        return (
          <>
            <nav id="main-nav">
              {studentNav}
            </nav>
            <div className="break"></div>
            {showMenu && !showNav &&
              <div 
                className="modules-list"
                ref={menuRef}
                onMouseEnter={() => setShowMenu(true)}
                onMouseLeave={() => setShowMenu(false)}
              >
                {allModules && allModules.map((module, i) => {
                  return (
                    <div key={i} className="module-item">
                      <Link 
                        to="#"
                        onClick={() => getModuleUrl(module.module)}
                      >
                        {"M"+ module.module +" - "+ module.name}
                      </Link>
                    </div>
                  )
                })}
              </div>
            }
          </>
        )
      } else if (authUser.roles.includes(ROLES.TEACHER)) {
        return (
          <>
            <nav id="main-nav">
              {teacherNav}
            </nav>
            <div className="break"></div>
            {showMenu && !showNav &&
              <div 
                className="modules-list"
                ref={menuRef}
                onMouseEnter={() => setShowMenu(true)}
                onMouseLeave={() => setShowMenu(false)}
              >
                {allModules && allModules.map((module, i) => {
                  return (
                    <div key={i} className="module-item">
                      <Link 
                        to="#"
                        onClick={() => getModuleUrl(module.module)}
                      >
                        {"M"+ module.module +" - "+ module.name}
                      </Link>
                    </div>
                  )
                })}
              </div>
            }
          </>
        )
      } else {
        return null
      }
    }
  }
}

export default Navigation
