import * as TYPES from "../constants/xapi/types";
import * as VERBS from "../constants/xapi/verbs";
import * as KEYS from "../constants/xapi/keys";
import * as routes from "../constants/routes";
import { KEY_FR } from "../constants/contentMap";
import moment from "moment";
import _ from "lodash";
import uuid from "uuid/v4";
import TinCan from "tincanjs";
import { sameActivity } from './same';

const initLrs = () => {
  try {
    return new TinCan.LRS({
      endpoint: process.env.REACT_APP_XAPI_URL,
      username: process.env.REACT_APP_XAPI_USER,
      password: process.env.REACT_APP_XAPI_PASSWORD,
      allowFail: false,
    });
  } catch (e) {
    console.log("Failed to setup LRS object: ", e.message);
  }
};

const getInteractionTypes = (type) =>
  ({
    QCMET: "choice",
    QCMOU: "choice",
    QCU: "choice",
    DRAG: "matching",
    MISSING: "fill-in",
    HIGHLIGHT: "matching",
  }[type] || "other");

const getDescription = (context, exos) => {
  const contents = exos
    .filter((exo) => exo.type === "message")
    .map(({ content }) => content);
  if (_.first(exos).activity)
    contents.unshift(
      _.get(
        context.activities.find((activity) => sameActivity(_.first(exos), activity)),
        "instruction"
      )
    );
  return contents.join(" ");
};

const getExoPath = (exercices) => {
  const id = _.get(exercices.find((exo) => exo.content_id === 0), "id");
  if (!id) console.log("EXO PATH UNDEFINED", payload);
  return `${process.env.REACT_APP_STRAPI_ENDPOINT}/exercices/${id}`;
};

const getExos = (context, answer) => (
  context.exercices.filter((exo) => (
    exo.path === answer.pathId &&
    exo.activity === answer.activityId &&
    exo.exercice === answer.exerciceId
  ))
);

const answerDoc = ({ context, payload }, option) => {
  const { answer } = payload;
  const exercices = getExos(context, answer);
  const doc = {
    actor: {
      account: {
        name: context.user.hatierToken,
        homePage: process.env.REACT_APP_URL
      },
      objectType: "Agent",
    },
    verb: answer.isCorrect ? VERBS.PASSED : VERBS.FAILED,
    version: "1.0.0",
    timestamp: moment(answer.tms).format(),
    target: {
      definition: {
        description: {
          [KEYS.LOCALE]: getDescription(context, exercices),
        },
        correctResponsesPattern: _.isArray(answer.correctAnswer)
          ? answer.correctAnswer
          : [answer.correctAnswer],
        type: "https://xapi.evidenceb.com/activities/cmi.interaction",
        interactionType: getInteractionTypes(answer.type),
        extensions: {
          [KEYS.DETAILS]: _.pick(_.first(exercices), ['module', 'path', 'activity', 'grade', 'subject', 'exercice'])
        },
      },
      id: getExoPath(exercices),
      objectType: "Activity"
    },
    stored: moment().format(),
    result: {
      duration: answer.duration,
      success: answer.isCorrect,
      score: answer.score,
      response: _.isArray(answer.answer)
        ? answer.answer.join(" [,] ")
        : answer.answer,
    },
    id: uuid(),
    authority: {
      mbox: "mailto:xapi-tools@adlnet.gov",
      name: "xapi-tools",
      objectType: "Agent",
    },
    context: {
      extensions: {
        [KEYS.ROLES]: context.user.roles,
        [KEYS.LOCATION]: {
          pathId: payload.currentPathId,
          activityId: payload.currentActivityId,
        }
      }
    }
  };
  if (answer.choices) {
    doc.target.definition.choices = _.map(answer.choices, (choice) => ({
      id: choice,
      description: { [KEYS.LOCALE]: "" },
    }));
  }
  return _.get(option, 'original') ? doc : new TinCan.Statement(doc);
};

const historyDoc = ({ context, payload }, option) => {
  const data = _.flattenDeep(payload.answers)
    .filter((item) => item && item.type !== "CHOICE")
    .sort((a, b) => a.tms - b.tms)
    .map((answer) => answerDoc({ context, payload: { answer } }));
  return data;
};

const launchActivityDoc = ({ context, payload }, option) => {
  const { activity } = payload;
  const doc = {
    actor: {
      account: {
        name: context.user.hatierToken,
        homePage: process.env.REACT_APP_URL
      },
      objectType: "Agent",
    },
    verb: VERBS.LAUNCH,
    version: "1.0.0",
    timestamp: moment().format(),
    target: {
      definition: {
        description: {
          [KEYS.LOCALE]: activity.name,
        },
        type: "https://xapi.evidenceb.com/activities",
        moreInfo: `https://xapi.evidenceb.com/${KEY_FR[activity.subject]}/${
          KEY_FR[activity.grade]
        }/module${activity.module}/${activity.path}/${activity.activity}/${
          routes.CHATBOT
        }`,
        extensions: {
          [KEYS.DETAILS]: _.pick(activity, ['module', 'path', 'activity', 'grade', 'subject'])
        },
      },
      id: `${process.env.REACT_APP_STRAPI_ENDPOINT}/activities/${activity.id}`,
      objectType: "Activity",
    },
    stored: moment().format(),
    id: uuid(),
    authority: {
      mbox: "mailto:xapi-tools@adlnet.gov",
      name: "xapi-tools",
      objectType: "Agent",
    },
    context: {
      extensions: {
        [KEYS.ROLES]: context.user.roles,
        [KEYS.LOCATION]: {
          pathId: payload.activity.path,
          activityId: payload.activity.activity,
        }
      }
    }
  };
  return _.get(option, 'original') ? doc : new TinCan.Statement(doc);
};

const initActivityDoc = ({ context, payload }, option) => {
  const { activity } = payload;
  const doc = {
    actor: {
      account: {
        name: context.user.hatierToken,
        homePage: process.env.REACT_APP_URL
      },
      objectType: "Agent",
    },
    verb: VERBS.INIT,
    version: "1.0.0",
    timestamp: moment().format(),
    target: {
      definition: {
        description: {
          [KEYS.LOCALE]: activity.name,
        },
        type: "https://xapi.evidenceb.com/activities",
        moreInfo: `https://xapi.evidenceb.com/${KEY_FR[activity.subject]}/${
          KEY_FR[activity.grade]
        }/module${activity.module}/${activity.path}/${activity.activity}/${
          routes.CHATBOT
        }`,
        extensions: {
          [KEYS.DETAILS]: _.pick(activity, ['module', 'path', 'activity', 'grade', 'subject'])
        },
      },
      id: `${process.env.REACT_APP_STRAPI_ENDPOINT}/activities/${activity.id}`,
      objectType: "Activity",
    },
    stored: moment().format(),
    id: uuid(),
    authority: {
      mbox: "mailto:xapi-tools@adlnet.gov",
      name: "xapi-tools",
      objectType: "Agent",
    },
    context: {
      extensions: {
        [KEYS.ROLES]: context.user.roles,
        [KEYS.LOCATION]: {
          pathId: payload.activity.path,
          activityId: payload.activity.activity,
        }
      }
    }
  };
  return _.get(option, 'original') ? doc : new TinCan.Statement(doc);
};


const terminateActivityDoc = ({ context, payload }, option) => {
  const { activity } = payload;
  const doc = {
    actor: {
      account: {
        name: context.user.hatierToken,
        homePage: process.env.REACT_APP_URL
      },
      objectType: "Agent",
    },
    verb: VERBS.TERMINATE,
    version: "1.0.0",
    timestamp: moment().format(),
    target: {
      definition: {
        description: {
          [KEYS.LOCALE]: activity.name,
        },
        type: "https://xapi.evidenceb.com/activities",
        moreInfo: `https://xapi.evidenceb.com/${KEY_FR[activity.subject]}/${
          KEY_FR[activity.grade]
        }/module${activity.module}/${activity.path}/${activity.activity}/${
          routes.CHATBOT
        }`,
        extensions: {
          [KEYS.DETAILS]: _.pick(activity, ['module', 'path', 'activity', 'grade', 'subject'])
        },
      },
      id: `${process.env.REACT_APP_STRAPI_ENDPOINT}/activities/${activity.id}`,
      objectType: "Activity",
    },
    stored: moment().format(),
    id: uuid(),
    authority: {
      mbox: "mailto:xapi-tools@adlnet.gov",
      name: "xapi-tools",
      objectType: "Agent",
    },
    context: {
      extensions: {
        [KEYS.ROLES]: context.user.roles,
        [KEYS.LOCATION]: {
          pathId: payload.answer.pathId,
          activityId: payload.answer.activityId,
        }
      }
    }
  };
  return _.get(option, 'original') ? doc : new TinCan.Statement(doc);
};

const maintainActivityDoc = ({ context, payload }, option) => {
  const { activity } = payload;
  const doc = {
    actor: {
      account: {
        name: context.user.hatierToken,
        homePage: process.env.REACT_APP_URL
      },
      objectType: "Agent",
    },
    verb: VERBS.MAINTAIN,
    version: "1.0.0",
    timestamp: moment().format(),
    target: {
      definition: {
        description: {
          [KEYS.LOCALE]: activity.name,
        },
        type: "https://xapi.evidenceb.com/activities",
        moreInfo: `https://xapi.evidenceb.com/${KEY_FR[activity.subject]}/${
          KEY_FR[activity.grade]
        }/module${activity.module}/${activity.path}/${activity.activity}/${
          routes.CHATBOT
        }`,
        extensions: {
          [KEYS.DETAILS]: _.pick(activity, ['module', 'path', 'activity', 'grade', 'subject'])
        },
      },
      id: `${process.env.REACT_APP_STRAPI_ENDPOINT}/activities/${activity.id}`,
      objectType: "Activity",
    },
    stored: moment().format(),
    id: uuid(),
    authority: {
      mbox: "mailto:xapi-tools@adlnet.gov",
      name: "xapi-tools",
      objectType: "Agent",
    },
    context: {
      extensions: {
        [KEYS.ROLES]: context.user.roles,
        [KEYS.LOCATION]: {
          pathId: payload.answer.pathId,
          activityId: payload.answer.activityId,
        }
      }
    }
  };
  return _.get(option, 'original') ? doc : new TinCan.Statement(doc);
};

const choiceDoc = ({ context, payload }, option) => {
  const { choice, activity } = payload;
  const doc = {
    actor: {
      account: {
        name: context.user.hatierToken,
        homePage: process.env.REACT_APP_URL
      },
      objectType: "Agent",
    },
    verb: VERBS.CHOICE,
    version: "1.0.0",
    timestamp: moment().format(),
    target: {
      definition: {
        description: {
          [KEYS.LOCALE]: choice.content,
        },
        type: "https://xapi.evidenceb.com/activities/cmi.interaction",
        extensions: {
          [KEYS.DETAILS]: _.pick(activity, ['module', 'path', 'activity', 'grade', 'subject'])
        },
      },
      id: `${process.env.REACT_APP_STRAPI_ENDPOINT}/activities/${activity.id}`,
      objectType: "Activity",
    },
    stored: moment().format(),
    id: uuid(),
    authority: {
      mbox: "mailto:xapi-tools@adlnet.gov",
      name: "xapi-tools",
      objectType: "Agent",
    },
    context: {
      extensions: {
        [KEYS.ROLES]: context.user.roles,
        [KEYS.LOCATION]: {
          pathId: payload.activity.path,
          activityId: payload.activity.activity,
        }
      }
    }
  };
  return _.get(option, 'original') ? doc : new TinCan.Statement(doc);
};

const idleDoc = ({ context, payload }, option) => {
  const { activity } = payload;
  const doc = {
    actor: {
      account : {
        name: context.user.hatierToken,
        homePage: process.env.REACT_APP_URL
      },
      objectType: "Agent"
    },
    verb: VERBS.IDLED,
    version: "1.0.0",
    timestamp: moment().format(),
    target: {
      definition: {
        description: {
          [KEYS.LOCALE]: activity.name,
        },
        type: "https://xapi.evidenceb.com/activities/cmi.interaction",
        extensions: {
          [KEYS.DETAILS]: _.pick(activity, ['module', 'path', 'activity', 'grade', 'subject'])
        },
      },
      id: `${process.env.REACT_APP_STRAPI_ENDPOINT}/activities/${activity.id}`,
      objectType: "Activity",
    },
    stored: moment().format(),
    id: uuid(),
    authority: {
      mbox: "mailto:xapi-tools@adlnet.gov",
      name: "xapi-tools",
      objectType: "Agent",
    },
    context: {
      extensions: {
        [KEYS.ROLES]: context.user.roles,
        [KEYS.LOCATION]: {
          pathId: payload.activity.path,
          activityId: payload.activity.activity,
        }
      }
    }
  }
  return _.get(option, 'original') ? doc : new TinCan.Statement(doc);
}

const disconnectDoc = ({ context, payload }, option) => {
  const { activity } = payload;
  const doc = {
    actor: {
      account : {
        name: context.user.hatierToken,
        homePage: process.env.REACT_APP_URL
      },
      objectType: "Agent"
    },
    verb: VERBS.DISCONNECTED,
    version: "1.0.0",
    timestamp: moment().format(),
    target: {
      definition: {
        description: {
          [KEYS.LOCALE]: activity.name,
        },
        type: "https://xapi.evidenceb.com/activities/cmi.interaction",
        extensions: {
          [KEYS.DETAILS]: _.pick(activity, ['module', 'path', 'activity', 'grade', 'subject'])
        },
      },
      id: `${process.env.REACT_APP_STRAPI_ENDPOINT}/activities/${activity.id}`,
      objectType: "Activity",
    },
    stored: moment().format(),
    id: uuid(),
    authority: {
      mbox: "mailto:xapi-tools@adlnet.gov",
      name: "xapi-tools",
      objectType: "Agent",
    },
    context: {
      extensions: {
        [KEYS.ROLES]: context.user.roles,
        [KEYS.LOCATION]: {
          pathId: payload.activity.path,
          activityId: payload.activity.activity,
        }
      }
    }
  }
  return _.get(option, 'original') ? doc : new TinCan.Statement(doc);
}

export const dispatchTypes = (type) =>
  ({
    [TYPES.ANSWER]: { doc: answerDoc, save: "PROTECTED" },
    [TYPES.HISTORY]: { doc: historyDoc, save: false },
    [TYPES.LAUNCH_ACTIVITY]: { doc: launchActivityDoc, save: "PROTECTED" },
    [TYPES.TERMINATE_ACTIVITY]: { doc: terminateActivityDoc, save: "PROTECTED" },
    [TYPES.MAINTAIN_ACTIVITY]: { doc: maintainActivityDoc, save: "PROTECTED" },
    [TYPES.INIT_ACTIVITY]: { doc: initActivityDoc, save: "PROTECTED" },
    [TYPES.CHOICE]: { doc: choiceDoc, save: "PROTECTED" },
    [TYPES.IDLE]: { doc: idleDoc, save: "PROTECTED" },
    [TYPES.DISCONNECT]: { doc: disconnectDoc, save: "DIRECT" },
  }[type]);

export const makeXapiDoc = (action, context, option) => {
  const type = dispatchTypes(action);
  const doc = type.doc(context, _.pick(option, ['original']));
  if (type.save && (option.save !== false)) saveXapiStatement(doc, type.save);
  return doc;
};

export const saveXapiDoc = (doc, type="PROTECTED") => {
  const statement = new TinCan.Statement(doc);
  saveXapiStatement(statement, type);
  return statement;
}

export const saveXapiStatement = (statement, type) => {
  console.log("SEND", type, statement);
  switch (type) {
    case 'PROTECTED':
      return queueStatements.push(statement);
    case 'DIRECT':
      return lrs.saveStatement(statement, { callback: () => null });
  }
};

export const getXapiStatements = (token, callback = () => null) => {
  lrs.queryStatements({
    params: {
      agent: new TinCan.Agent({
        account: {
          name: token,
          homePage: process.env.REACT_APP_URL
        },
        objectType: "Agent"
      }),
      limit: 2147483647,
    },
    callback: callback
  })
}

/* ********************************************************************************** */
/* ********************                                          ******************** */
/* ****                                                                          **** */
/* ****                   SEND STATEMENT CONNECTION PROTECTED                    **** */
/* ****                                                                          **** */
/* ********************                                          ******************** */
/* ********************************************************************************** */

// voir pour mettre les statements dans le local storage et set le local storage onload (avec tincanjs)

const REFRESH_REQUEST_TIME = 200;

const proxyOnChange = (callback) => ({
  set: (target, property, value) => {
    target[property] = value;
    if (property === 'length') callback();
    return true;
  }
});

const onQueueChange = async () => {
  if (!queueStatements.length) return;

  if (!sendingStatements.length)
    sendingStatements.push(...queueStatements.splice(0, queueStatements.length));
}

const onSendingChange = async () => {
  if (!sendingStatements.length) return;

  lrs.saveStatements(sendingStatements, {
    callback: (err, res) => setTimeout(() => {
      const queue = sendingStatements.splice(0, sendingStatements.length)
      if (err !== null)
        queueStatements.unshift(...queue);
      else
        onQueueChange();
    }, REFRESH_REQUEST_TIME)
  });
}

// this queue is to stacks statements if the client is pending the server response from a old request
const queueStatements = new Proxy([], proxyOnChange(onQueueChange));
// this queue is the statements send to the request pending
const sendingStatements = new Proxy([], proxyOnChange(onSendingChange));

const lrs = initLrs();

