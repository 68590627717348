// Language
export const LOCALE = "en-US"

// Context Extension
export const ROLES = "https://xapi.evidenceb.com/users/roles";
export const LOCATION = "https://xapi.evidenceb.com/chat/location";

// Object Extension
export const DETAILS = "https://xapi.evidenceb.com/object/details";
export const OPTIONS = "https://xapi.evidenceb.com/object/options";
export const CONTENT = "https://xapi.evidenceb.com/object/content";
