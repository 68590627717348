import React, { useContext, useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { StoreContext } from "./Store";
import useApi from '../hooks/useApi';
import useParseLocation from "../hooks/useParseLocation";
import useUpdate from "../hooks/useUpdate";
import useCheckUpdate from "../hooks/useCheckUpdate";

import { makeXapiDoc } from "../utils/xapi";

import * as XAPI_TYPES from "../constants/xapi/types";

import { getActivityType, getActivity } from "../utils/activities";

import * as ROLES from "../constants/roles";
import * as PATH_MAP from "../constants/contentMap";
import { IDLE_TIME } from "../config/constants";

const AI = ({ children }) => {
  const {
    user: { user },
    data: {
      indexes,
      answers,
      sequence,
      dispatchAI,
      xapiHistory,
      pushXapiHistory,
      dispatchSequence,
      history: { activitiesState },
    },
    cms: { activities, modules, paths, exercices },
    cmsAll,
    drawer: { stepId: activityTeacher, pathId: pathTeacher },
  } = useContext(StoreContext);

  const { createHistory, updateHistory, updateTracking, createTracking } = useApi({ subject, grade, modId: modul });

  const {
    modul,
    path: pathStudent,
    activityId: activityStudent,
    grade,
    subject,
  } = useParseLocation();
  const moduleId = parseInt(modul.replace(/^module/, ""));
  let pathId, activityId;

  if (user && user.roles.includes(ROLES.STUDENT)) {
    pathId = pathStudent;
    activityId = activityStudent;
  } else if (user && user.roles.includes(ROLES.TEACHER)) {
    pathId = pathTeacher;
    activityId = activityTeacher;
  }
  pathId = parseInt(pathId);
  activityId = parseInt(activityId);

  const savePersistance = (cb) => {
    console.log("SAVE PERSISTANCE");
    if (user && user.roles.includes(ROLES.TEACHER))
      return cb();
    const postHistory = (activitiesState[pathId] && activitiesState[pathId][activityId]) ? updateHistory : createHistory;
    const postTracking = (activitiesState[pathId] && activitiesState[pathId][activityId]) ? updateTracking : createTracking;
    const data = [
      indexes.localeId,
      PATH_MAP.FR_KEY[subject],
      PATH_MAP.FR_KEY[grade],
      moduleId,
      pathId,
      activityId,
    ];
    postHistory(...data, sequence[pathId][activityId])
    .catch(console.error)
    .then(
      postTracking(...data, answers[pathId][activityId])
      .catch(console.error)
      .then(cb)
    );
  }

  const setOnLeave = () => {
    const onLeave = (e) => {
      const type = getActivityType(
        { activity: activityId, path: pathId, module: moduleId },
        activities
      );
      const activity = getActivity(
        type,
        { activity: activityId, path: pathId, module: moduleId, grade, subject },
        activities
      );
      if (!activity) return;
      const context = {
        payload: { activity },
        context: { exercices, activities, user },
      };
      makeXapiDoc(XAPI_TYPES.DISCONNECT, context, {
        save: user && user.roles.includes(ROLES.STUDENT),
      });
      e.returnValue = "Attention, vous allez quitter l'application.";
      return "Attention, vous allez quitter l'application.";
    }
    if (user && user.roles.includes(ROLES.STUDENT)) {
      window.onbeforeunload = onLeave;
      window.onunload = onLeave;
    }
  }

  const setIdle = () => {
    let idle = null;
    const onIdle = () => {
      const type = getActivityType(
        { activity: activityId, path: pathId, module: moduleId },
        activities
      );
      const activity = getActivity(
        type,
        { activity: activityId, path: pathId, module: moduleId, grade, subject },
        activities
      );
      if (!activity) return;
      const context = {
        payload: { activity },
        context: { exercices, activities, user },
      };
      makeXapiDoc(XAPI_TYPES.IDLE, context, {
        save: user && user.roles.includes(ROLES.STUDENT),
      });
    }
    const resetIdle = () => {
      clearTimeout(idle)
      idle = setTimeout(onIdle, IDLE_TIME);
    }
    ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'].forEach((name) => {
      window.addEventListener(name, resetIdle, true);
    })

  }

  const clearConsole = (cb) => {
    if (user && user.roles.includes(ROLES.TEACHER))
      return cb();
    if (!(activitiesState[pathId] && activitiesState[pathId][activityId]))
      return cb();
    dispatchSequence({ type: 'RESET_SEQUENCE_ACTIVITY', payload: { pathId, activityId }});
    const data = [
      indexes.localeId,
      PATH_MAP.FR_KEY[subject],
      PATH_MAP.FR_KEY[grade],
      moduleId,
      pathId,
      activityId,
    ];
    updateHistory(...data, []).catch(console.error).then(cb)
  }

  const launchActivity = () => {
    const type = getActivityType(
      { activity: activityId, path: pathId, module: moduleId },
      activities
    );
    const activity = getActivity(
      type,
      { activity: activityId, path: pathId, module: moduleId, grade, subject },
      activities
    );
    if (!activity) return;
    clearConsole(() => {
      const context = {
        payload: { activity },
        context: { exercices, activities, user },
      };
      const doc = makeXapiDoc(XAPI_TYPES.LAUNCH_ACTIVITY, context, {
        save: user && user.roles.includes(ROLES.STUDENT),
      });
      dispatchAI({ type: "CALL", payload: doc, context: { historyLen: xapiHistory.length + 1 }});
      pushXapiHistory(doc);
    })
  };

  const initActivity = () => {
    const type = getActivityType(
      { activity: activityId, path: pathId, module: moduleId },
      activities
    );
    const activity = getActivity(
      type,
      { activity: activityId, path: pathId, module: moduleId, grade, subject },
      activities
    );
    if (!activity) return;
    console.log("INIT");
    const context = {
      payload: { activity },
      context: { exercices, activities, user },
    };
    const doc = makeXapiDoc(XAPI_TYPES.INIT_ACTIVITY, context, {
      save: user && user.roles.includes(ROLES.STUDENT),
    });
    dispatchAI({ type: "CALL", payload: doc, context: { historyLen: xapiHistory.length + 1 }});
    pushXapiHistory(doc);
  };

  const choiceHandler = (choice) => {
    const type = getActivityType(
      { activity: activityId, path: pathId, module: moduleId },
      activities
    );
    const activity = getActivity(
      type,
      { activity: activityId, path: pathId, module: moduleId, grade, subject },
      activities
    );
    console.log("CHOICE", choice);
    const context = {
      payload: { choice, activity },
      context: { user },
    };
    const doc = makeXapiDoc(XAPI_TYPES.CHOICE, context, {
      save: user && user.roles.includes(ROLES.STUDENT),
    });
    dispatchAI({ type: "CALL", payload: doc, context: { historyLen: xapiHistory.length + 1 }});
    pushXapiHistory(doc);
  };

  const indexesHandler = () => {
    switch (indexes.action) {
      case "LAUNCH_ACTIVITY":
        launchActivity();
        break;
      case "RESET_SEQUENCE_ACTIVITY":
        clearConsole(() => null);
        break;
    }
  };

  const checkAnswerUpdate = ([lastAnswers]) => (
    _.get(lastAnswers, `${pathId}.${activityId}.length`) !== _.get(answers, `${pathId}.${activityId}.length`)
  );

  const answerHandler = () => {
    const answer = _.last(_.get(answers, `${pathId}.${activityId}`));
    if (!answer) return;
    console.log("ANSWER");
    savePersistance(() => {
      if (answer.type === "CHOICE") return choiceHandler(answer);
      const context = {
        payload: { 
          answer: answer,
          currentActivityId: activityId,
          currentPathId: pathId
        },
        context: { exercices, activities, user },
      };
      const doc = makeXapiDoc(XAPI_TYPES.ANSWER, context, {
        save: user && user.roles.includes(ROLES.STUDENT),
      });
      dispatchAI({ type: "CALL", payload: doc, context: { historyLen: xapiHistory.length + 1 }});
      pushXapiHistory(doc);
    });
  };


  const setWorkerContext = () => {
    const context = {
      ...cmsAll,
      history: xapiHistory
    };
    dispatchAI({ type: "INIT_WORKER", payload: context });
  };

  useEffect(setOnLeave, []);
  useEffect(setIdle, []);
  useEffect(setWorkerContext, [modules, exercices, activities, paths]);
  useEffect(initActivity, [pathId, activityId]);
  useCheckUpdate(answerHandler, [answers], checkAnswerUpdate);
  useUpdate(indexesHandler, [indexes]);

  return <Fragment>{children}</Fragment>;
};

export default withRouter(AI);
