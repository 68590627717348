import React, { useEffect, useRef } from "react";
import { cloneDeep } from 'lodash';

function useCheckUpdate(callback = () => null, args, check = () => true) {
  const didMount = useRef(false);
  const lastArgs = useRef(cloneDeep(args));
  useEffect(() => {
    const needUpdate = (didMount.current && check(lastArgs.current));
    lastArgs.current = cloneDeep(args);
    didMount.current = true;
    if (needUpdate) return callback(lastArgs.current);
  }, args);
}

export default useCheckUpdate;
