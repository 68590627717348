import { useEffect, useRef } from "react";
import useForceUpdate from "./useForceUpdate";

function useQueue(callback = () => null) {
  const queue = useRef([]);
  const processingElement = useRef();
  const clearInterval = useRef(null);
  const forceUpdate = useForceUpdate();

  const push = (...args) => {
    queue.current.push(...args);
    forceUpdate();
  }

  const shift = () => {
    const ret = queue.current.shift();
    forceUpdate();
    return ret;
  }

  const reset = () => {
    (clearInterval.current || (() => null))();
    clearInterval.current = null;
    queue.current = [];
    forceUpdate();
    return queue.current;
  }

  useEffect(() => {
    if (processingElement.current === queue.current[0])
      return ;
    processingElement.current = queue.current[0];
    if (processingElement.current)
      clearInterval.current = callback(processingElement.current);
  });

  useEffect(() => () => {
    (clearInterval.current || (() => null))();
    clearInterval.current = null;
    queue.current = [];
  }, [])

  return [queue.current, push, shift, reset];
}

export default useQueue;
