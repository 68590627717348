import React, { useContext, useEffect, useState, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

import { Button, Card } from 'antd';
import Activity from '../../containers/Activity';
import { StoreContext } from '../../containers/Store';

import * as ROLES from '../../constants/roles';
import useParseLocation from '../../hooks/useParseLocation';
import * as ROUTES from '../../constants/routes';

import '../../scss/ChatbotStudent.scss'

const getFinalTestId = (activities, pathNumber) => {
  const currentPathActivities = activities.filter(a => a.path == pathNumber);
  return currentPathActivities.length + 1;
}

const getBreadcrumbActivity = (activityId, finalTestId) => {
  switch (activityId) {
    case 0:
      return "Test diagnostique";
    case finalTestId:
      return "Test final";
    default:
      return "Activité " + activityId;
  } 
}

const Chatbot = (props) => {
  const {
    user: {
      user
    },
    data: {
      history: { activitiesState },
      sequence
    }, 
    cms: {
      modules,
      modeemplois,
      activities,
    },
    drawer: {
      stepId,
    },
  } = useContext(StoreContext);
  
  const { subject, grade, modul, path: pathId, activityId } = useParseLocation();

  const regex = /\d+/g;
  const moduleNumber = modul.match(regex);
  const finalTestId = getFinalTestId(activities, pathId);
  const breadcrumbParcours = (pathId > 0) 
      ? <span>Matrice {pathId}</span>
      : '';
  const breadcrumbActivity = getBreadcrumbActivity(parseInt(activityId), finalTestId);

  return (
    <div className="chatbot">
      <div className="breadcrumb"><span>Module {moduleNumber[0]}</span> &gt; {breadcrumbParcours} {breadcrumbParcours? "> " : ""} <span> {breadcrumbActivity} </span></div>
      <Activity />
    </div>
  )

};

export default withRouter(Chatbot);