import config from "../config/merge.modules.config.json";
import _ from "lodash";
import { zeroPad } from "./stringOperations";

const hatierModules = ({
  modId,
  subjectId,
  gradeId,
  exercicesData,
  pathsData,
  modulesData,
  activitiesData,
  modeemploisData,
}) => {
  const exercices = exercicesData.data
    .filter(
      (item) =>
        item.subject === subjectId &&
        item.grade === gradeId &&
        item.module === modId
    )
    .sort((a, b) => a.key - b.key);

  const activities = activitiesData.data
    .filter(
      (item) =>
        item.subject === subjectId &&
        item.grade === gradeId &&
        item.module === modId
    )
    .map((item) => {
      const key = `${zeroPad(item.path, 2)}${zeroPad(item.activity, 2)}`;
      item.key = key;
      return item;
    })
    .sort((a, b) => parseInt(a.key) - parseInt(b.key));

  const paths = pathsData.data.filter(
    (item) =>
      item.subject === subjectId &&
      item.grade === gradeId &&
      item.module === modId
  );

  // Returns all paths available for subject/grade
  const allPaths = pathsData.data
    .filter((item) => item.subject === subjectId && item.grade === gradeId)
    .sort((a, b) => a.module - b.module);

  // Returns module according to subject/grade/location ID
  const modules = modulesData.data.filter(
    (item) =>
      item.subject === subjectId &&
      item.grade === gradeId &&
      item.module === modId
  );

  // Returns all modules available for subject/grade
  const allModules = modulesData.data
    .filter((item) => item.subject === subjectId && item.grade === gradeId)
    .sort((a, b) => a.module - b.module);

  const modeemplois = modeemploisData.data;

  return {
    exercices,
    activities,
    paths,
    allPaths,
    modules,
    allModules,
    modeemplois,
  };
};

const adaptiveModules = ({
  modId,
  subjectId,
  gradeId,
  exercicesData,
  pathsData,
  modulesData,
  activitiesData,
  modeemploisData,
}) => {
  const hashOldNew = () => {
    const flat = _.flatten(
      config.modules.map(({ paths, data: { module } }) =>
        paths.map((item) => ({ ...item, module }))
      )
    );
    return flat.reduce((obj, { pathId, module, number }) => {
      const path = pathsData.data.find(({ id }) => id === pathId);
      return {
        ...obj,
        [`${path.module}-${path.path}`]: { module, path: number },
      };
    }, {});
  };
  const oldToNew = hashOldNew();

  const setNewPath = (item) => ({
    ...item,
    ..._.get(oldToNew, `${item.module}-${item.path}`),
  });

  // Returns all modules available for subject/grade
  const allModules = config.modules
    .sort((a, b) => a.module - b.module);

  // Returns module according to subject/grade/location ID
  const modules = allModules.filter(({ data }) => data.module === modId);

  const paths = pathsData.data.map(setNewPath)

  // const TO_KEEP = [
  //   "5ebbb7f7203e2c00179b0f8c", "5ebbb7f7203e2c00179b0f8d", "5ebbb7f8203e2c00179b0f8e",
  //   "5ebbb7f8203e2c00179b0f8f", "5ebbb7f9203e2c00179b0f90", "5ebbb7f9203e2c00179b0f91" ,
  //   "5ebbb7fa203e2c00179b0f92", "5ebbb7fb203e2c00179b0f93", "5ebbb7fb203e2c00179b0f94" ,
  //   "5ebbb7fc203e2c00179b0f95", "5ebbb7fc203e2c00179b0f96", "5ebbb7fd203e2c00179b0f97" ,
  //   "5ebbb7ff203e2c00179b0f9b", "5ebbb7ff203e2c00179b0f9c", "5ebbb800203e2c00179b0f9d" ,
  //   "5ebbb800203e2c00179b0f9e", "5ebbb801203e2c00179b0f9f", "5ebbb801203e2c00179b0fa0" ,
  //   "5ebbb9ef203e2c00179b1309", "5ebbb9ef203e2c00179b130a", "5ebbb9f0203e2c00179b130b" ,
  //   "5ebbb9f1203e2c00179b130c", "5ebbb9f1203e2c00179b130d", "5ebbb9f2203e2c00179b130e" ,
  //   "5ebbb9f3203e2c00179b130f", "5ebbb9f3203e2c00179b1310", "5ebbb9f4203e2c00179b1311" ,
  //   "5ebbb9f7203e2c00179b1315", "5ebbb9f7203e2c00179b1316", "5ebbb9f8203e2c00179b1317",
  //   "5ebbb9f8203e2c00179b1318", "5ebbb9f9203e2c00179b131a" ,
  //   "5ebbb9fa203e2c00179b131b", "5ebbb9fa203e2c00179b131c" ,
  //   "5f22cf535977cb0017969aa0", "5ebbbac1203e2c00179b1476", "5ebbbac1203e2c00179b1477" ,
  //   "5ebbbac2203e2c00179b1478", "5ebbbac3203e2c00179b1479", "5ebbbac3203e2c00179b147a" ,
  //   "5ebbbac4203e2c00179b147b", "5ebbbac4203e2c00179b147c", "5ebbbac4203e2c00179b147d" ,
  //   "5f22d1805977cb0017969aa1", "5ebbbac6203e2c00179b147f", "5ebbbac6203e2c00179b1480" ,
  //   "5ebbbac8203e2c00179b1484", "5ebbbac9203e2c00179b1485", "5ebbbac9203e2c00179b1486" ,
  //   "5ebbbacc203e2c00179b148a", "5ebbbacc203e2c00179b148b", "5ebbbacd203e2c00179b148c" ,
  //   "5ebbb91f203e2c00179b119c", "5ebbb920203e2c00179b119d", "5ebbb920203e2c00179b119e" ,
  //   "5ebbb921203e2c00179b119f", "5ebbb922203e2c00179b11a0", "5ebbb922203e2c00179b11a1" ,
  //   "5ebbb923203e2c00179b11a2", "5ebbb923203e2c00179b11a3", "5ebbb923203e2c00179b11a4" ,
  //   "5ebbb924203e2c00179b11a5", "5ebbb924203e2c00179b11a6", "5ebbb925203e2c00179b11a7" ,
  //   "5ebbb927203e2c00179b11ab", "5ebbb927203e2c00179b11ac", "5ebbb928203e2c00179b11ad" ,
  //   "5ebbb92a203e2c00179b11b1", "5ebbb92b203e2c00179b11b2", "5ebbb92b203e2c00179b11b3" ,
  //   "5f22d7ac5977cb0017969aa2", "5ebbbb81203e2c00179b15e3", "5ebbbb82203e2c00179b15e4" ,
  //   "5ebbbb82203e2c00179b15e5", "5ebbbb83203e2c00179b15e6", "5ebbbb83203e2c00179b15e7" ,
  //   "5ebbbb84203e2c00179b15e8", "5ebbbb84203e2c00179b15e9", "5ebbbb85203e2c00179b15ea" ,
  //   "5ebbbb85203e2c00179b15eb", "5ebbbb86203e2c00179b15ec", "5ebbbb86203e2c00179b15ed" ,
  //   "5ebbbb88203e2c00179b15f1", "5ebbbb89203e2c00179b15f2", "5ebbbb89203e2c00179b15f3" ,
  //   "5ebbbb89203e2c00179b15f4", "5ebbbb8a203e2c00179b15f5", "5ebbbb8a203e2c00179b15f6" ,
  // ];

  const exercices = exercicesData.data
    .map(({ difficulty, ...item }) => {
      const { path=0, module } = item.path ?
        _.get(oldToNew, `${item.module}-${item.path}`) :
        _.pick(_.get(oldToNew, `${item.module}-1`), 'module');
      const exercice = path === 0 ? item.exercice + (item.module - 1) * 20 : item.exercice
      const key = `${module}${zeroPad(path, 3)}${zeroPad(item.activity, 3)}${zeroPad(exercice, 3)}${zeroPad(item.key % 100, 3)}`
      return {
        ...item, exercice, path, module, key,
        ...(difficulty && { difficulty: JSON.stringify(_.reduce(eval(`(${difficulty})`), (obj, value, key) => {
          const newPath = _.get(oldToNew, `${item.module}-${key.replace('path', '')}.path`);
          value *= _.get(config, `modules.${module - 1}.pathDifficulty.${newPath}`) || 1;
          return {...obj, [`path${newPath}`]: value }
        }, {}))})
      }
    })
    // .map(setNewPath)
    .filter(
      (item) => !(_.get(config.modules.find((conf) => conf.data.module == item.module), 'ignoredExos', []).includes(item.id))
    )
    .sort((a, b) => a.key - b.key);

    // console.log("EXO TO DELETE", exercices.filter((exo) => (
    //   exo.subject === subjectId && exo.grade === gradeId && !TO_KEEP.includes(exo.id) && exo.module === 1 && exo.path === 0
    // )).map(({id}) => id))

    // console.log("DELETED ", _.keys(_.groupBy(exercicesData.data.filter((elem) => (
    //   config.modules[0].ignoredExos.includes(elem.id)
    // )), (elem) => `${elem.exercice}${elem.module}`)).length)

    console.log("CURRENT ", _.groupBy(exercices.filter((elem) => (
      elem.subject === subjectId && elem.grade === gradeId && elem.module === modId && elem.path === 0
    )), (elem) => elem.exercice))
  
    // const exodiag1 = exercices.filter((exo) => (
    //   exo.subject === subjectId &&
    //   exo.grade === gradeId &&
    //   exo.module === 1 &&
    //   exo.path === 0
    // )).map(({id}) => id);
    // console.log("TEST IDS not in KEEP: ", exodiag1.filter((elem) => !TO_KEEP.includes(elem)))
    // console.log("TEST KEEP not in IDS: ", TO_KEEP.filter((elem) => !exodiag1.includes(elem)))

  const activities = activitiesData.data
    .map(setNewPath)
    .map((item) => {
      // let key = item.path < 10 ? `0${item.path}` : item.path;
      // key += item.activity < 10 ? `0${item.activity}` : item.activity;
      const key = `${zeroPad(item.path, 2)}${zeroPad(item.activity, 2)}`;
      item.key = key;
      return item;
    })
    .sort((a, b) => parseInt(a.key) - parseInt(b.key));

  const modeemplois = modeemploisData.data;

  return {
    exercices: exercices.filter((item) => item.subject === subjectId && item.grade === gradeId && item.module === modId ),
    activities: activities.filter((item) => item.subject === subjectId && item.grade === gradeId && item.module === modId),
    paths: paths.filter((item) => item.subject === subjectId && item.grade === gradeId && item.module === modId),
    modules: modules.map((item) => item.data),
    allModules: allModules.map((item) => item.data),
    allPaths: paths,
    allActivities: activities,
    allExercices: exercices,
    modeemplois,
  };
};

const methods = {
  hatier: hatierModules,
  adaptive: adaptiveModules,
};

const setModules = (method, data) => {
  return (methods[method] || hatierModules)(data);
};

export default setModules;
