import React, { useContext, useState, useEffect } from 'react';
import { StoreContext } from '../../containers/Store';
import { redirect } from '../../utils/location';
import { getLastUrl } from '../../utils/history';
import _ from 'lodash';

import '../../scss/Home.scss';


const Home = () => {

  const clickModuleHandler = (id) => {
    redirect(`francais/premieres/module${id}`)
  }

  const {
    cms: {
      modeemplois: [ modeemploi ]
    },
    data: {
      xapiHistory
    },
    cmsAll: {
      modules
    }
  } = useContext(StoreContext);

  const [ loaded, setLoaded ] = useState(false);

  const initPage = () => {
    if (_.get(xapiHistory, 'length')) {
      const defaultSubject = 'francais'
      const defaultGrade = 'premieres'
      const next = getLastUrl(xapiHistory, null, defaultSubject, defaultGrade)
      if (next !== '/')
        redirect(next);
      else
        setLoaded(true);
    } else {
      setLoaded(true);
    }  
  }

  useEffect(initPage, []);

  if (!loaded) return null;

  return <div className="home-page">
    <h1>Bienvenue 🤗</h1>
    <div className="message-home"><div>{modeemploi.description}</div></div>
    {
      modules.map((mod) => (
        <div key={mod.module} className="card module-title">
          <h1>{`Module ${mod.module} - ${mod.name}`}</h1>
          <button className="btn btn-validate" onClick={() => clickModuleHandler(mod.module)}>Commencer</button>
        </div>
      ))
    }
    <div className="logos">
      <img src="logo-idf.png" alt="logo Région Île-de-france" />
      <img src="logo-bpi-france.jpg" alt="logo Bpi France" />
      <img src="logo-cap-digital.jpg" alt="logo Cap Digital" />
      <img src="logo-academie-paris.jpg" alt="logo Académie de Paris" />
      <img src="logo-pv.png" alt="logo Lycée Paul Valéry" />
    </div>
  </div>
}

export default Home;