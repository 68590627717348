import { useState, useEffect, useRef } from 'react';
import io from "socket.io-client";
import useForceUpdate from './useForceUpdate';
import _ from 'lodash';

const defaultOnKicked = () => {
  alert('Vous avez été déconnecté');
  window.location.href = "https://google.com"
}


function useAuthSync(token, app_name="", onKicked=defaultOnKicked) {

  const socket = useRef(null);
  const firstConnection = useRef(false);
  const forceUpdate = useForceUpdate();

  const initSocket = () => {
    console.log("AUTHSYNC", process.env.REACT_APP_AUTHSYNC_URL)
    socket.current = io(process.env.REACT_APP_AUTHSYNC_URL);
    socket.current.on('connect', function () {
      if (!firstConnection.current) {
        firstConnection.current = true;
        socket.current.emit('authenticate', { app: app_name, token });
      }
      forceUpdate();
    });
    socket.current.on('reconnect', forceUpdate);
    socket.current.on('discconnect', forceUpdate);
    socket.current.on('kick', onKicked);
  };

  useEffect(initSocket, []);
  return _.get(socket.current, 'connected', false)
}

export default useAuthSync;