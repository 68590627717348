import { useState, useRef } from 'react';
import { StoreContext } from '../containers/Store';
import { getFlagsConfig } from '../utils/flagsConfig';
import useWorker from './useWorker';
import axios from 'axios';
import _ from 'lodash';

const actions = {
  INIT_WORKER:  { server: false,  client: true,   url: null,          request: null },
  SEND_CONTEXT: { server: true,   client: false,  url: '/context',    request: ({ payload }) => (payload) },
  CALL:         { server: true,   client: true,   url: '/statement',  request: ({ payload, context }) => ({ statement: payload, length: context.historyLen }) },
}

let config = JSON.parse(process.env.REACT_APP_FLAGS_CONFIG);

getFlagsConfig((data) => config = data);

function useAI() {
  const [ results, setResults ] = useState([]);
  const forceWorker = useRef(false);
  const [, dispatchWorker ] = useWorker('back', (result) => {
    console.log('AIXAPI RESPONSE WORKER', result);
    setResults([ ...results, result ]);
  });

  const dispatch = async (action) => {
    const actionConfig = actions[action.type];
    if (!actionConfig) return console.error('AIXAPI ACTION TYPE NOT HANDLE : ', action.type);
    const path = process.env.REACT_APP_AIXAPI_URL + actionConfig.url;
    action.startAt = new Date();
    if (actionConfig.server && (config.server && (!forceWorker.current || !actionConfig.client))) {
      console.log('AIXAPI CALL SERVER', action);
      axios.post(path, actionConfig.request(action), {
        headers: {
          "User-Id": action.context.userId,
          "Cms-Md5": action.context.cmsMd5
        }
      })
        .then(({ data }) => {
          if (!data) return;
          console.log('AIXAPI RESPONSE SERVER', data);
          data.startAt = action.startAt;
          setResults([ ...results, data ])
        })
        .catch((error) => {
          console.log('AIXAPI ERROR SERVER', error);
          if (actionConfig.client && config.client) {
            console.log('CALL WORKER', action);
            dispatchWorker(action);
          }
          else {
            console.log('AIXAPI CALL REDO');
            if (config.client)
              forceWorker.current = true;
            setResults([ ...results, { actions: [{"type": "REDO_CALL", "payload": {}}]} ])
          }
        })
    }
    else if (config.client) {
      forceWorker.current = false;
      dispatchWorker(action);
    }
  }

  return [ results, dispatch ];
}

export default useAI;