import React, { useEffect, useContext, createRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import uniqid from 'uniqid';

import { StoreContext } from './Store';
import DragAndDrop from '../components/DragAndDrop';
import MissingValues from '../components/MissingValues';
import HightlightText from '../components/HightlightText';
import Message from '../components/Message';
import Answer from '../components/Answer';
import Qcm from '../components/Qcm';
import ButtonAction from '../components/ButtonAction';
import Choice from '../components/Choice';
import useParseLocation from '../hooks/useParseLocation';
import useApi from '../hooks/useApi';
import * as ROUTES from '../constants/routes';
import * as ROLES from '../constants/roles';
import * as ANSWERS from '../constants/sequenceAnswers';
import '../scss/Activity.scss';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

const Activity = ({ history, location: { pathname } }) => {
  const {
    user: {
      user
    },
    data: {
      sequence,
      dispatchSequence,
      deleteItemPromise,
      answers,
      dispatchAnswers,
      dispatchIndexes,
      ia,
      dispatchIA,
      history: { activitiesState },
    },
    drawer: {
      stepId,
      setStepId,
      pathId: pathTeacher
    }
  } = useContext(StoreContext);


  const { subject, grade, modul, path: pathStudent, activityId: activityStudent } = useParseLocation();

  useEffect(() => {
    console.log('Activity.js is mounted');
    return () => console.log('Activity.js unmounted');
  }, [])

  let pathId, activityId;

  if (user && user.roles.includes(ROLES.STUDENT)) {
    pathId = pathStudent;
    activityId = activityStudent;
  } else if (user && user.roles.includes(ROLES.TEACHER)) {
    pathId = pathTeacher;
    activityId = stepId;
  }

  useEffect(() => {
    if (pathId && activityId && sequence && sequence[pathId]) console.log('In Activity, sequence[pathId][activityId]', sequence[pathId][activityId]);
  }, [pathId, activityId])

  // const { createHistory, updateHistory, updateTracking, createTracking } = useApi({ subject, grade, modId: modul });

  const [localSequence, setLocalSequence] = useState([]);
  const [localAnswers, setLocalAnswers] = useState([]);

  useEffect(() => {
    if (
      stepId === 0 &&
      activitiesState &&
      activitiesState[0] &&
      pathname.includes(ROUTES.PATH)
    ) {
      const localSequence = sequence[0][0];
      setLocalSequence(localSequence);
    } else if (
      activitiesState &&
      stepId > 0 &&
      activitiesState[pathId] &&
      activitiesState[pathId][stepId] &&
      pathname.includes(ROUTES.PATH)
    ) {
      const localSequence = sequence[pathId][stepId];
      setLocalSequence(localSequence);
    } else if (
      pathId &&
      !sequence[pathId]
    ) {
      setLocalSequence([]);
    } else {
      if (
        pathId !== undefined &&
        activityId !== undefined
      ) {
        const localSequence = sequence[pathId][activityId];
        setLocalSequence(localSequence || []);
      } else {
        setLocalSequence([]);
      }
    }
  }, [sequence, stepId, pathId, activityId, activitiesState]);

  // useEffect(() => {
  //   // console.log('In Activity, answers: ', answers);
  //   if (
  //     pathId !== undefined &&
  //     pathId !== null &&
  //     activityId !== undefined &&
  //     pathId !== null &&
  //     answers &&
  //     answers[pathId] &&
  //     answers[pathId][activityId]
  //   ) {
  //     const localAnswers = answers[pathId][activityId];
  //     setLocalAnswers(localAnswers || []);
  //   }
  // }, [answers, pathId, activityId]);

  useEffect(() => {
    scrollToBottom();
  });
  
  const onClick = (e, action, payload) => {
    if (
      user && user.roles.includes(ROLES.TEACHER) &&
      action === 'LAUNCH_ACTIVITY'
    ) {
      dispatchSequence({ type: 'CLICK', payload: { pathId, activityId } });
      dispatchIndexes({ type: 'LAUNCH_ACTIVITY', payload: {} });
    }

    if (user && user.roles.includes(ROLES.STUDENT)) {
      dispatchSequence({ type: 'CLICK', payload: { pathId, activityId } });

      if (action === 'LAUNCH_ACTIVITY')
        dispatchIndexes({ type: 'LAUNCH_ACTIVITY', payload: {} });
      if (action === 'CHANGE_ACTIVITY')
        dispatchIndexes({ type: 'CHANGE_ACTIVITY', payload: {} });
      // const postHistory = (activitiesState[pathId] && activitiesState[pathId][activityId]) ? updateHistory : createHistory;
      // const postTracking = (activitiesState[pathId] && activitiesState[pathId][activityId]) ? updateTracking : createTracking;
  
      // For each Activity, check if it has already been done. If true, then postHistory, if not, createHistory
      if (action === 'NEXT_ACTIVITY' && ia && ia.pathId) {
        dispatchIndexes({
          type: 'NEXT_ACTIVITY',
          payload: { pathId: ia.pathId, sequence, answers, postHistory, postTracking }
        });
        dispatchIA({ type: 'GAMELOOP', payload: {}})
      }
      if (action === 'FINAL_TEST') {
        dispatchIndexes({ type: 'FINAL_TEST', payload: { sequence, answers, postHistory, postTracking  } })
      };
      if (action === 'NEXT_PATH') {
        dispatchIndexes({
          type: 'NEXT_PATH',
          payload: { sequence, answers, postHistory, postTracking }
        });
      }
      if (action === 'NEXT_MODULE') {
        dispatchIndexes({
          type: 'NEXT_MODULE',
          payload: { sequence, answers, postHistory, postTracking }
        });
      }
      if (action === 'REDO_ACTIVITY') {
        dispatchIndexes({
          type: 'REDO_ACTIVITY',
          payload: { sequence, answers, postHistory, postTracking, errors: payload }
        })
      }
      if (action === 'FINISH_PATH') {
        dispatchIndexes({
          type: 'FINISH_PATH',
          payload: { pathId: pathId, sequence, answers, postHistory, postTracking }
        });
      }
    }
  }

  const getAnswer = (answer, index, type, choices) => {
    const [ lastItem ] = sequence[pathId][activityId].slice(-1);
    dispatchSequence({ type: 'ANSWER', payload: { pathId, activityId, answer, index } });
    dispatchAnswers({ type, payload: { pathId, activityId, lastItem, choices, answer, index } });
  }

  const getChoice = (answer) => {
    if (user && user.roles.includes(ROLES.STUDENT)) {
      // const postHistory = (activitiesState[pathId] && activitiesState[pathId][activityId]) ? updateHistory : createHistory;
      // const postTracking = (activitiesState[pathId] && activitiesState[pathId][activityId]) ? updateTracking : createTracking;

      const lastItem = sequence[pathId][activityId].slice(-1)[0];
      dispatchSequence({ type: 'CHOICE', payload: { lastItem, answer, pathId, activityId } });
      dispatchAnswers({ type: 'CHOICE', payload: { answer, pathId, activityId } });
      const itemId = sequence[pathId][activityId].findIndex(item => item.type === 'choice');
      dispatchSequence({ type: 'DELETE_ITEM', payload: { lastItem, pathId, activityId, itemId } });
      // console.log(itemId);
      // if (answer === ANSWERS.VALIDATE_SEQUENCE) { 
      //   deleteItemPromise(lastItem, pathId, activityId, itemId)
      //     .then(() => {
      //       // we need NEXT_ACTIVTY after DELETE_ITEM (because if not, choice buttons are saved in back)
      //       dispatchIndexes({ type: 'NEXT_ACTIVITY', payload: { pathId, sequence, answers, postHistory, postTracking, history } })
      //     });
      // } else if (answer === ANSWERS.CONTINUE_SEQUENCE) {
      //   dispatchIndexes({ type: 'NEXT_EXERCICE', payload: {} });
      //   dispatchIA({ type: 'GAMELOOP', payload: {}});
      //   dispatchSequence({ type: 'DELETE_ITEM', payload: { lastItem, pathId, activityId, itemId } });
      // } else if (answer === ANSWERS.RESTART_SEQUENCE) {
      //   deleteItemPromise(lastItem, pathId, activityId, itemId)
      //   .then(() => {
      //     // we need NEXT_ACTIVTY after DELETE_ITEM (because if not, choice buttons are saved in back)
      //     window.location.replace(`/${subject}/${grade}/${modul}/${pathId}/${activityId}/${ROUTES.CHATBOT}`);
      //   });
      // }
    }
  }

  const scrollableNodeRef = createRef();

  const scrollToBottom = () => {
    const responseBubbles = document.querySelectorAll('.message-bubble.right');
    if (responseBubbles && responseBubbles.length > 0) {
      scrollableNodeRef.current.scrollTop = scrollableNodeRef.current.scrollHeight - scrollableNodeRef.current.clientHeight;
    }
  }

  return (
    <div className="chat">
      <SimpleBar className="scrollable" scrollableNodeProps={{ ref: scrollableNodeRef }}>
        {
          localSequence
            .map((item) => {
              if (item) {
                if (item.type === "message" || item.type === "feedback") {
                  return <Message
                    message={item.content}
                    key={uniqid()}
                  />
                } else if (item.type === "button") {
                  return <ButtonAction
                    text={item.content}
                    onClick={(event) => onClick(event, item.action, item.payload)}
                    key={uniqid()}
                  />
                } else if (item.type === "qcm" || item.type === "QCMET" || item.type === "QCMOU") {
                  const cleanContent = Array.from(item.content
                    .substr(1, item.content.length - 2)
                    .split("||"));
                  return <Qcm
                    content={cleanContent}
                    getAnswer={(answer, index, type) => getAnswer(answer, index, type, cleanContent)}
                    key={uniqid()}
                    type={item.type}
                  />
                } else if (item.type === "choice") {
                  return <Choice
                    content={item.content}
                    getChoice={getChoice}
                    key={uniqid()}
                  />
                } else if (item.type === "answer") {
                  return <Answer
                    answer={item.content}
                    key={uniqid()}
                  />
                } else if (item.type === "drag") {
                  const cleanContent = Array.from(item.content
                    .substr(1, item.content.length - 2)
                    .split("||"));
                  return <DragAndDrop
                    content={cleanContent}
                    getAnswer={(answer, index) => getAnswer(answer, index, 'DRAG', cleanContent)}
                    key={uniqid()}
                  />
                } else if (item.type === "missing_values") {
                  const cleanContent = Array.from(item.content
                    .substr(1, item.content.length - 2)
                    .split("||"));
                    if(cleanContent.length === 1 && cleanContent[0] === ""){
                      cleanContent[0] = "__"
                    } 
                  return <MissingValues
                    content={cleanContent}
                    getAnswer={(answer, index) => getAnswer(answer, index, 'MISSING', cleanContent)}
                    key={uniqid()}
                  />
                } else if (item.type === "highlight_text") {
                  const cleanContent = item.content;
                  return <HightlightText
                    content={cleanContent}
                    getAnswer={(answer, index) => getAnswer(answer, index, 'HIGHLIGHT', cleanContent)}
                    key={uniqid()}
                  />
                }
              }
            })
        }
      </SimpleBar>
    </div>
  );
}

export default withRouter(Activity);
