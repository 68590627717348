import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import { StoreContext } from '../containers/Store';

import * as ROLES from '../constants/roles';
import * as ROUTES from '../constants/routes';
import Loading from '../components/Loading';

const Splash = () => {
  const { 
    user: { 
      user: authUser,
      lastIndexes,
      dispatchLastIndexes
    },
    location: {
      subject,
      grade,
      modul
    },
    loading: {
      loading
    },
    cms: {
      paths
    },
    data: {
      sequence
    },
    error: {
      error
    }
  } = useContext(StoreContext);

  console.log('AuthUser', authUser);

  const [redirectRoute, setRedirectRoute] = useState();

  const moduleId = parseInt(modul.replace('module', ''));

  useEffect(() => {
    console.log('SPLASH IS MOUNTED');
  }, []);

  const computePathFromTestConclusion = (seq) => {
    console.log("sequence", seq);
    const testConclusion = seq[0][0][seq[0][0].length-2].content;
    console.log('testConclusion', testConclusion);
    const attributedPath = paths.filter(p => {
      return (p.module === moduleId &&
      testConclusion.trim() === p.difficulty_student.trim())
    })[0];
    console.log('attributedPath', attributedPath)
    return (attributedPath) ? attributedPath.path : 1 // should not happen, but should it happen, path1
  }

  useEffect(() => {
    let redirectRoute;
    console.log(loading);
    console.log('In SPLASH lastIndexes', lastIndexes);
    if (Object.values(loading).every(l => l === false)) {
      if (authUser && authUser.roles.includes(ROLES.TEACHER)) {
        redirectRoute = `/${subject}/${grade}/${modul}/${ROUTES.DASHBOARD}`;
        setRedirectRoute(redirectRoute)
      } else if (authUser && authUser.roles.includes(ROLES.STUDENT)) {
        if (lastIndexes) {
          // if (lastIndexes && lastIndexes.pathId === 0 && sequence && sequence[0] && sequence[0][0] && sequence[0][0][sequence[0][0].length-2]) {
          //   // happens if user has disconnected during first activity after test
          //   // IA should recompute path
          //   const pathId = computePathFromTestConclusion(sequence);
          //   redirectRoute = `/${subject}/${grade}/${modul}/${pathId}/1/${ROUTES.PATH}`;
          //   setRedirectRoute(redirectRoute);
          // } else {
          //   redirectRoute = `/${subject}/${grade}/${modul}/${lastIndexes.pathId}/${lastIndexes.activityId}/${ROUTES.PATH}`;
          //   setRedirectRoute(redirectRoute);
          // }
          redirectRoute = `/${subject}/${grade}/${modul}/${lastIndexes.pathId}/${lastIndexes.activityId}/${lastIndexes.pathId ? ROUTES.PATH : ROUTES.CHATBOT}`;
          setRedirectRoute(redirectRoute);
        } else {
          redirectRoute = `/${subject}/${grade}/${modul}/0/0/${ROUTES.CHATBOT}`;
          setRedirectRoute(redirectRoute);
        }
      } else {
        console.log('AAAAAAAAA SPLASH', `/${subject}/${grade}/${modul}/${ROUTES.DEMO}`)
        setRedirectRoute(`/${subject}/${grade}/${modul}/${ROUTES.DEMO}`);
      }
    }
  }, [authUser, lastIndexes, loading, sequence])

 if (!redirectRoute && !error) {
    return (
      <Loading />
    )
  } else {    
    console.log('redirectRoute', redirectRoute);
    return <Redirect to={redirectRoute}/>;
  }
};

export default Splash;