export const PASSED = {
  id: "https://xapi.evidenceb.com/verbs/passed",
  display: { "en-US": "passed" },
};

export const FAILED = {
  id: "https://xapi.evidenceb.com/verbs/failed",
  display: { "en-US": "failed" },
};

export const LAUNCH = {
  id: "https://xapi.evidenceb.com/verbs/launched",
  display: { "en-US": "launched" },
};

export const INIT = {
  id: "https://xapi.evidenceb.com/verbs/initialized",
  display: { "en-US": "initialized" },
};

export const CHOICE = {
  id: "https://xapi.evidenceb.com/verbs/decided",
  display: { "en-US": "decided" }
};

export const TERMINATE = {
  id: "https://xapi.evidenceb.com/verbs/terminated",
  display: { "en-US": "terminated" }
};

export const MAINTAIN = {
  id: "https://xapi.evidenceb.com/verbs/maintained",
  display: { "en-US": "maintained" }
};

export const DISCONNECTED = {
  id: "https://xapi.evidenceb.com/verbs/disconnected",
  display: { "en-US": "disconnected" }
};

export const IDLED = {
  id: "https://xapi.evidenceb.com/verbs/idled",
  display: { "en-US": "idled" }
};

export const CHANGED = {
  id: "https://xapi.evidenceb.com/verbs/changed",
  display: { "en-US": "changed" }
};

export const POSTED = {
  id: "https://xapi.evidenceb.com/verbs/posted",
  display: { "en-US": "posted" }
};

export const RECALLED = {
  id: "https://xapi.evidenceb.com/verbs/recalled",
  display: { "en-US": "recalled" }
};

export const REQUESTED = {
  id: "https://xapi.evidenceb.com/verbs/requested",
  display: { "en-US": "requested" }
};


