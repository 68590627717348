import React, { useContext, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, useRouteMatch } from 'react-router-dom';

import { StoreContext } from '../../containers/Store';
import Classroom from './Classroom';

import ClassroomImage from '../../assets/images/classroom.jpg';
import { Select } from 'antd';
import '../../scss/Dashboard.scss';

const { Option } = Select;


const Classrooms = ({ url, classrooms }) => <Fragment>
  <ul>
    {classrooms.length ? classrooms.map((classroom) => {
      return (
        <li key={classroom.name}>
          <Link to={`${url}/${classroom.name}`} >
            {/* <img src={ClassroomImage} /> */}
            <span>{classroom.name} </span>
          </Link>
        </li>
      )
    }) : "Vous n'avez pas encore de classe(s), veuillez en créer sur votre ENT"}
  </ul>
</Fragment>


const Dashboard = () => {
  const {
    error: {
      error
    }
  } = useContext(StoreContext);

  let { url, path } = useRouteMatch();

  useEffect(() => {
    console.log('DASHBOARD TEACHER MOUNTED');
    return () => console.log('DASHBOARD TEACHER UNMOUNTED');
  }, [])

  // Retrieve teacher's classes
  let teacherData = JSON.parse(localStorage.getItem('teacherData'));
  let classrooms = []
  for (let item in teacherData.classes) {
    let obj = {
      name: item,
      students: teacherData.classes[item]
    }
    classrooms.push(obj)
  }

  return (
    <div className="dashboard-container teacher">
      <h2>Pour voir les résultats de vos élèves, sélectionnez une classe.</h2>
      <Classrooms url={url} classrooms={classrooms} />
      <Route path={`${path}/:classroomId`} render={() => <Classroom classrooms={classrooms} />} />
    </div>
  )
};

export default Dashboard;