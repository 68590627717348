import { useEffect, useState, useRef } from "react";
import useForceUpdate from "./useForceUpdate";
import * as workers from "../workers";

function useWorker(worker_name, callback = () => null) {
  const forceUpdate = useForceUpdate();
  const initWorker = () => {
    console.log(`INIT ${worker_name}Worker`);
    const WorkerConstructor = workers[worker_name];
    if (!WorkerConstructor)
      throw new Error(`"${worker_name}" is not handle as worker.`);
    const worker = new WorkerConstructor();
    worker.onmessage = messageHandler;
    worker.onerror = errorHandler;
    setPost(() => worker.postMessage.bind(worker));
    return () => worker.terminate();
  };

  const messageHandler = ({ data: { result, error } }) => {
    if (error) return console.error(`${worker_name}Worker error:`, error);
    console.log(`${worker_name}Worker return results:`, result);
    results.current = [...results.current, result];
    forceUpdate();
  };

  const errorHandler = ({ filename, lineno, message }) => {
    console.error(
      `Error ${worker_name}Worker: ${filename}, Line: ${lineno}, ${message}`
    );
  };

  const resultHandler = () => {
    if (results.current.length) callback(results.current[results.current.length - 1]);
  };

  const results = useRef([]);
  const [post, setPost] = useState(null);

  useEffect(resultHandler, [results.current]);
  useEffect(initWorker, []);

  return [results.current, post];
}

export default useWorker;
