import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { flattenDepth } from 'lodash'
import { Row, Col, Statistic, Card, Icon, Button } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import { StoreContext } from '../../containers/Store';

import useParseLocation from '../../hooks/useParseLocation';
import useApi from '../../hooks/useApi';

import "../../scss/Dashboard.scss"

import { getXapiStatements } from '../../utils/xapi';
import { getDetailsFromXapiDoc } from '../../utils/history';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import * as VERBS from '../../constants/xapi/verbs';
import * as KEYS from '../../constants/xapi/keys';
import { sameModule } from '../../utils/same';

const DashboardStudent = () => {

  const { Meta } = Card;

  const {
    user: {
      user
    },
    data: {
      answers,
      statistics,
      dispatchStatistics,
      indexes,
      xapiHistory,
    },
    cms: {
      exercices,
      activities
    },
    cmsAll
  } = useContext(StoreContext);

  const [moduleAnswers, setModuleAnswers] = useState([]);
  const [isUserData, setIsUserData] = useState(true);

  const { subject, grade, modul, path: pathId } = useParseLocation();

  const modId = modul ? parseInt(modul.replace('module', '')) : 1;

  const { readTrackingAll, allModules } = useApi({
    subject,
    grade,
    modId
  });

  let { studentId } = useParams();
  const [ history, setHistory ] = useState([]);
  const [ data, setData ] = useState({});

  const setModuleHistory = (statements) => {
    const moduleHistory = statements.filter((statement) => sameModule(getDetailsFromXapiDoc(statement), { module: modId }))
    setHistory(moduleHistory);
    console.log(moduleHistory.map((item) => [item.verb.id, item.target.definition.extensions[KEYS.DETAILS]]))
    setIsUserData(!!moduleHistory.length);
  }

  useEffect(() => {
    if (user.roles.includes(ROLES.STUDENT))
      setModuleHistory(xapiHistory);
    else
      getXapiStatements(studentId, (err, res) => res && setModuleHistory(res.statements));
  }, [studentId]);

  useEffect(() => {
    console.log("SET_DATA");
    const pathId = _.get(_.maxBy(history.filter((statement) => statement.verb.id === VERBS.LAUNCH.id),
        `target.definition.extensions.${KEYS.DETAILS}.path`),
      `target.definition.extensions.${KEYS.DETAILS}.path`);

    const answers = history.filter((statement) => [VERBS.PASSED.id, VERBS.FAILED.id].includes(statement.verb.id));
    const correctAnswers = answers.filter((statement) => statement.verb.id === VERBS.PASSED.id)

    const longestCorrectSequence = answers.reduce((obj, statement) => {
      const value = (statement.verb.id === VERBS.PASSED.id) ? obj.current + 1 : 0;
      return { max: Math.max(value, obj.max), current: value }
    }, { max: 0, current: 0 }).max

    const period = answers.reduce((duration, statement) => duration.add(statement.result.duration), moment.duration(0)).asMilliseconds();
    const averagePeriod = moment.duration((period / answers.length) || 0).asSeconds();
    const totalPeriod = moment.duration(period).asMinutes();

    setData({
      pathId,
      correctAnswersCount : correctAnswers.length,
      answersCount: answers.length,
      longestCorrectSequence,
      averagePeriod,
      totalPeriod
    })
  }, [history]);


  const [localAnswers, setLocalAnswers] = useState([[[]]]);

  useEffect(() => {
    if (moduleAnswers) {
      console.log('moduleAnswers LALALA', moduleAnswers);
      dispatchStatistics({
        type: 'NUMBER_UNIQUE_EXERCICES',
        payload: {
          moduleAnswers,
        }
      });
      dispatchStatistics({
        type: 'MEAN_TIME',
        payload: {
          moduleAnswers,
        }
      });
      dispatchStatistics({
        type: 'TRAINING_TIME',
        payload: {
          moduleAnswers,
        }
      });
      dispatchStatistics({
        type: 'CORRECT_RATE',
        payload: {
          moduleAnswers,
        }
      });
      dispatchStatistics({
        type: 'CORRECT_NUMBER',
        payload: {
          moduleAnswers,
        }
      });
      dispatchStatistics({
        type: 'CONSECUTIVE_SUCCESSES',
        payload: {
          moduleAnswers,
        }
      });
      dispatchStatistics({
        type: 'SUCCESS_IF_RANDOM',
        payload: {
          moduleAnswers,
          exercices,
          indexes
        }
      });

      // setIsUserData(true)

      // console.log("CONSOLE statistics Dispatch");
    }

    return () => console.log('DASHBOARD UNMOUNTED');
  }, [moduleAnswers]);

  useEffect(() => {
    const token = (user.roles.includes(ROLES.STUDENT) ? user.hatierToken : studentId);
    if (token) {
      setIsUserData(false);
      let localAnswers = [[[]]];

      // If user is student, dashboard based on answers from store
      if (user.roles.includes(ROLES.STUDENT)) {
        localAnswers = answers.filter((act, i) => i > 0 && act !== undefined)

        let moduleAnswers = [];
        moduleAnswers = flattenDepth(Object.values(localAnswers), 2)
        .filter(ans => !!ans)
        .filter(ans => ans.pathId > 0);
        setModuleAnswers([...moduleAnswers]);
        setLocalAnswers([...localAnswers]);
        
      // If user is teacher, must call tracking endpoint to get each student's data
      } else {
        readTrackingAll(token).then((res) => {
          if (res.results) {
            let data = res.results.filter( act => act.module === modId && act.path > 0 )
            if (data.length) {
              data.forEach(act => {
                const pathId = act.path;
                const activityId = act.activity;
                const answers = JSON.parse(act.tracking);

                if (
                  pathId !== undefined &&
                  activityId !== undefined &&
                  localAnswers[pathId]
                ) {
                  localAnswers[pathId][activityId] = answers;
                } else if (
                  pathId !== undefined &&
                  activityId !== undefined &&
                  !localAnswers[pathId]
                ) {
                  localAnswers[pathId] = [];
                  localAnswers[pathId][activityId] = answers;
                }
              });
              setIsUserData(true);
            } else {
              console.log('setIsUserData(false)')
              setIsUserData(false);
            }

            let moduleAnswers = [];
            moduleAnswers = flattenDepth(Object.values(localAnswers), 2)
            .filter(ans => !!ans)
            .filter(ans => ans.pathId > 0);
            setModuleAnswers([...moduleAnswers]);
            setLocalAnswers([...localAnswers]);      
          }
        });
      }
    }
  }, [studentId]);


  const extractData = (modId, value) => {
    if (allModules) {
      if (allModules.filter(p =>
        p.module === modId).length > 0) {
        return allModules.filter(p =>
          p.module === modId)[0][value];
      } else {
        return ""
      }
    } else {
      return ""
    }
  }

  return console.log('In Dashboard Student, isUserData', isUserData) || (
    <div className="dashboard-container student">
      {isUserData || user.roles.includes(ROLES.STUDENT) ?
        <div className="dashboard">
          <Row gutter={16} type="flex" justify="space-around" align="center" style={{ paddingBottom: '16px' }}>
            <Col md={24} xs={24}>
              <Card>
                <Meta
                  title={"Module " + modId + " - " + extractData(modId, "name")}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={16} type="flex" justify="space-around" align="center" style={{ paddingBottom: '16px' }}>
            <Col md={12} xs={24}>
              <Card>
                {<Statistic
                  title="Nombre de bonnes réponses / Questions répondues"
                  // value={
                  //   ((statistics && !!statistics.correctNumber) ? statistics.correctNumber : '0')
                  //   + ' / ' +
                  //   ((statistics && !!statistics.numberUniqueExercices) ? statistics.numberUniqueExercices : '0')
                  // }
                  value={`${data.correctAnswersCount} / ${data.answersCount}`}
                />}
              </Card>
            </Col>
            <Col md={12} xs={24}>
              <Card>
                <Statistic
                  title="Temps de réponse moyen"
                  // value={(statistics && !!statistics.meanTime) ? statistics.meanTime : '0'}
                  value={data.averagePeriod}
                  precision={2}
                  suffix="s."
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={16} type="flex" justify="space-around" align="center" style={{ paddingBottom: '16px' }}>
            <Col md={12} xs={24}>
              <Card>
                {<Statistic
                  title="Taux de bonnes réponses"
                  // value={(statistics && !!statistics.correctRate) ? statistics.correctRate * 100 : '0'}
                  value={((data.correctAnswersCount / data.answersCount) * 100) || 0}
                  precision={0}
                  suffix={'%'}
                />}
              </Card>
            </Col>
            <Col md={12} xs={24}>
              <Card>
                <Statistic
                  title="Bonnes réponses consécutives"
                  // value={(statistics && !!statistics.consecutiveSuccesses) ? statistics.consecutiveSuccesses : '0'}
                  value={data.longestCorrectSequence}
                  prefix={<Icon type="like" />}
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={16} type="flex" justify="space-around" align="center" style={{ paddingBottom: '16px' }}>
            <Col md={12} xs={24}>
              <Card>
                {<Statistic
                  title="Temps total d'entraînement"
                  // value={(statistics && !!statistics.trainingTime) ? statistics.trainingTime : '0'}
                  value={data.totalPeriod}
                  precision={0}
                  suffix={'min'}
                />}
              </Card>
            </Col>
            <Col md={12} xs={24}>
              <Card>
                <Statistic
                  title="Nombre de bonnes réponses quand on répond au hasard"
                  value={(statistics && !!statistics.ifRandom) ? statistics.ifRandom : '0'}
                  precision={2}
                />
              </Card>
            </Col>
          </Row>
        </div>
        : <div className="no-user-data">L'élève n'a pas encore terminé au moins une activité ou n'a fait que le test diagnostique.</div>}
    </div>
  );
};

export default DashboardStudent;