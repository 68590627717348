import React from 'react';


export default () => (
  <div className="centered-container">
    <div>
        <h1 style={{ fontSize: 54 }}>Oups...</h1>
        <iframe src="https://giphy.com/embed/vhsNmFjuN4WDS" width="480" height="480" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>
        <p style={{ fontSize: 24 }}>Adaptiv'Langue n'est pas accessible par ce lien. Connectez-vous <a href="https://monlycee.net/">via votre ENT.</a></p>
    </div>
  </div>
);
