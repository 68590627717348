import { useEffect, useState, useRef } from 'react';
import { getXapiStatements } from '../utils/xapi';
import _ from 'lodash';

const THRESHOLD = 3

function useXapiHistory(user) {
  const [ history, setHistory ] = useState(null);
  const tries = useRef(0);
  useEffect(() => {
    console.log(user);
    if (!user || !user.hatierToken) return;
    tries.current = 0;
    const getWithRetry = () => getXapiStatements(user.hatierToken, (err, res) => {
      if (err) {
        if (tries.current++ >= THRESHOLD) return;
        return getWithRetry();
      }
      setHistory(_.orderBy(res.statements || [], [function(statement) { return Date.parse(statement.timestamp) }], 'asc'));
    });
    getWithRetry();
  }, [user]);

  const pushHistory = (statement) => setHistory([ ...history, statement ]);

  return [ history, pushHistory ];
}

export default useXapiHistory;
