import * as types from '../constants/types';
import { FR_KEY } from "../constants/contentMap";

const generateFinalActivity = (activity) => {
  return {
    ...activity,
    grade: FR_KEY[activity.grade],
    subject: FR_KEY[activity.subject],
    name: "Final Test",
    id: `FINAL_TEST_${activity.module}_${activity.path}_${activity.activity}`
  }
}


export const getActivityType = ({ path, activity, module }, activities) => {
  if (path === 0)
    return types.DIAG;
  if (activity <= activities.filter((elem) => path === elem.path && elem.module === module).length)
    return types.MATRICE;
  if (activity === activities.filter((elem) => path === elem.path && elem.module === module).length + 1)
    return types.FINAL;
  return types.ERROR;
}

export const getActivity = (type, activity, activities) => {
  switch (type) {
    case types.DIAG:
    case types.MATRICE:
      return activities.find((elem) => elem.path === activity.path && elem.activity === activity.activity && elem.module === activity.module);
    case types.FINAL:
      return generateFinalActivity(activity)
  }
  return null;
}