


import React, { useContext, useEffect } from 'react';
import { useParams, useRouteMatch, Switch, Route } from 'react-router-dom';

import { StoreContext } from '../../containers/Store';
import Error404 from '../Error404';

import DashboardStudent from '../ContentStudent/DashboardStudent';
import useParseLocation from '../../hooks/useParseLocation';
import { redirect } from '../../utils/location';

import noticePDF from '../../assets/notice.pdf'
import '../../scss/Classroom.scss';
import { Modal, Icon } from 'antd';

const { confirm } = Modal;

const FrK11Auth = require('fr-k11-auth');
// Overriding SDK API host
const ApiClient = new FrK11Auth.ApiClient();
ApiClient.basePath = process.env.REACT_APP_AUTH1_URL;

const api = new FrK11Auth.DefaultApi(ApiClient);


const Student = ({ }) => {
  
  const {
  } = useContext(StoreContext);

  useEffect(() => {
    console.log('CLASSROOM MOUNTED');
    return () => console.log('CLASSROOM UNMOUNTED');
  }, [])

  let { path } = useRouteMatch();
  let { classroomId, studentId } = useParams();
  const { subject, grade, modul } = useParseLocation();

  const confirmModal = () => {
    confirm({
      title: 'Êtes-vous sûr(e) de voir supprimer cet(te) élève de votre tableau de bord ?',
      icon: <Icon type="exclamationcircle"/>,
      content: 'Cette action est irréversible. L\'élève pourra toutefois continuer d\'accéder aux modules.',
      cancelText: 'Annuler',
      onOk() {
        blacklistStudent();
      }
    });
  };

  // API call to blacklist student from teacher's class
  const blacklistStudent = () => {
    let authorization = "Bearer " + localStorage.getItem('hatier-token');
    api.deleteTokenUserGUIDGuidDelete(studentId, authorization).then((data) => {
      console.log('Student was successfully blacklisted - ' + data);
      removeStudentFromTeacherData();
    }, (error) => {
      console.error(error);
    });
  };

  // Updates teacher's classes in localStorage 
  const removeStudentFromTeacherData = () => {
    let teacherData = JSON.parse(localStorage.getItem('teacherData'));
    let teacherClass = teacherData.classes[classroomId];
    if (teacherClass) {
      teacherData.classes[classroomId] = teacherClass.filter((student) => student.GUID !== studentId);
      localStorage.setItem('teacherData', JSON.stringify(teacherData))
    }
    redirect('/' + subject + '/' + grade + '/' + modul + '/journal/' + classroomId);
  };

  return (
      <div className="classroom-container">
        <br />
        <br />
        <h3> Tableau de bord de l'élève 
          <a href={noticePDF} target="_blank">Notice</a>
        </h3>
        <Switch>
          <Route exact path={path} render={() => <DashboardStudent />} />
          <Route component={Error404} />
        </Switch>
        <a onClick={confirmModal}>Supprimer l'élève du tableau de bord</a>
      </div>
  )
};

export default Student;