import React, { useContext, Fragment } from 'react';

import { StoreContext } from '../../containers/Store';
import PathTeacher from './PathTeacher';
import { Row, Col, Collapse } from 'antd';
import { stringifyId } from '../../utils/stringOperations';
import _ from 'lodash';
import { toItalic } from '../../utils/stringOperations';
import ActivityDrawerTeacher from '../../components/ActivityDrawerTeacher';
import ClearFix from '../../components/ClearFix';
import configModules from '../../config/merge.modules.config.json';

import "../../scss/Gallery.scss"

const PathDetails = ({ path: id, module: mid, description, difficulty }) => <Fragment key={'fragment-' + id}>
  <Col md={8} xs={24} key={id}>
    <Collapse>
      <Collapse.Panel showArrow={false} header={<div className="card" style={{ background: configModules.colors[`${mid}-${id}`] }}>
          <div className="inner-card">
            <h2 className="title">{id} - {configModules.titles[`${mid}-${id}`]}</h2>
            <div className="content">
              <h4><em>Objectif</em></h4>
              <p>{toItalic(description)}</p>
              <h4> <em>Difficultés visées</em> </h4>
              <p>{toItalic(difficulty)}</p>
              <div className="shadow" />
            </div>
          </div>
        </div>}>
        <PathTeacher pathId={id} color={configModules.colors[`${mid}-${id}`]} />
      </Collapse.Panel>
    </Collapse>
  </Col>
  {!(id % 3) ? <ClearFix key={'clear-fix-' + id} /> : null}
</Fragment>

export const Gallery = () => {

  const {
    data: {
      indexes,
      dispatchIndexes,
      dispatchIA
    },
    cms: {
      modules,
      paths
    },
    drawer: {
      setRightDrawer,
      setRightDrawerTitle,
      setStepId,
      setPathId
    },
  } = useContext(StoreContext);

  const references = modules[0].know_more && modules[0].know_more.split('||') || [];

  const displayPaths = _.map(_.sortBy(paths, 'path'), PathDetails);

  return (
    <div id="teacher-gallery">
      <div className="card module-title">
        <h1>{`Module ${modules[0].module.toString()} - ${modules[0].description || ''}`}</h1>
        <button className="btn btn-validate"
          onClick={() => {
            setRightDrawerTitle('Questions du test diagnostique');
            setRightDrawer(true);
            setPathId(0);
            setStepId(0);
            dispatchIA({ type: 'GAMELOOP', payload: {} });
            dispatchIndexes({
              type: 'SET_INDEXES',
              payload: {
                ...indexes,
                pathId: 0,
                activityId: 0,
                exerciceId: 0,
                contentId: 0,
              }
            })
          }}
        >Questions du test diagnostique</button>
      </div>

      <Row gutter={[ 12, 12 ]}>
        {displayPaths}
      </Row>
      <ActivityDrawerTeacher />
    </div>
  )
}

export default Gallery;